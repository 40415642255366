import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { FiSearch } from "react-icons/fi";
import Swal from "sweetalert2";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const StudentNotification = () => {
    const [getStudentNotification, setgetStudentNotification] = useState([]);
    const handleGetnotificationData = async () => {
        try {
            const getNotifiaction = await axios.get("api/admin/get-student-notification");
            console.log("getNotifiaction***", getNotifiaction);
            if (getNotifiaction?.data?.isNotificationFound === true) {
                setgetStudentNotification(getNotifiaction?.data?.data);
            }
        } catch (error) {
            if (error.response.status === 400) {
                const message_400 = error?.response?.data?.message;
                toast.error(message_400);
            } else if (error.response.status === 401) {
                const message_401 = error?.response?.data?.message;
                toast.error(message_401);
            } else if (error.response.status === 404) {
                const message_404 = error?.response?.data?.message;
                console.log("message_404", message_404);
                toast.error(message_404);
            } else if (error.response.status === 500) {
                const message_500 = error?.response?.data?.message;
                toast.error(message_500);
            } else {
                toast.error("something went wrong");
            }
        }
    };

    useEffect(() => {
        handleGetnotificationData();
    }, []);
    return (
        <Layout>
            <form className="d-flex mb-3 my-3">
                <div className="row w-100 justify-content-between g-3">
                    <div className="col-lg-5 col-md-6 col-sm-12">
                        <div className="input-group ">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search Address"
                                aria-label="Search"
                                aria-describedby="button-addon2"
                            />
                            <button
                                className="button-styles"
                                type="button"
                                id="button-addon2"
                            >
                                <FiSearch />
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <div className="table-responsive">
                <div>
                    <div className="table-responsive table-box">
                        <table className="table table-bordered">
                            <thead>
                                <tr className="table-head">
                                    <th>Notification Id</th>
                                    <th>Course</th>
                                    <th>StudentName</th>
                                    <th>centerName</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getStudentNotification.map((nft, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{nft.Course}</td>
                                        <td>{nft.StudentName}</td>
                                        <td>{nft.centerName}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right" />
        </Layout>
    );
};
export default StudentNotification;
