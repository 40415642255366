import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { FiSearch } from "react-icons/fi";
import Swal from "sweetalert2";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const CoursePageSection = () => {
  const [getAllCourseData, setGetAllCourseData] = useState([]);
  const [updateId, setUpdateId] = useState("");
  const [courseData, setCourseData] = useState({
    CourseShortName: "",
    CourseFullName: "",
    CourseDurationMonths: "",
    CourseDurationYears: "",
    CourseEligiblity: "",
  });

  const [courseUpdateData, setCourseUpdateData] = useState({
    CourseShortName: "",
    CourseFullName: "",
    CourseDurationMonths: "",
    CourseDurationYears: "",
    CourseEligiblity: "",
  });

  const handleOnChange = (key, value) => {
    setCourseData((courseData) => ({
      ...courseData,
      [key]: value,
    }));
  };

  const handleUpdateOnChange = (key, value) => {
    setCourseUpdateData((courseUpdateData) => ({
      ...courseUpdateData,
      [key]: value,
    }));
  };

  const handleDelete = (id) => {
    Swal.fire({
      text: "Are you sure you won't be deleted?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const setDeleteCourseData = await axios.delete(
            `api/admin/course/delete-course/${id}`
          );
          if (setDeleteCourseData.data.isCourseDeleted === true) {
            toast.success(setDeleteCourseData.data.msg);
            handleGetCourseData();
          }
        } catch (error) {
          if (error.response.status === 400) {
            const message_400 = error?.response?.data?.message;
            toast.error(message_400);
          } else if (error.response.status === 401) {
            const message_401 = error?.response?.data?.message;
            toast.error(message_401);
          } else if (error.response.status === 404) {
            const message_404 = error?.response?.data?.message;
            console.log("message_404", message_404);
            toast.error(message_404);
          } else if (error.response.status === 500) {
            const message_500 = error?.response?.data?.message;
            toast.error(message_500);
          } else {
            toast.error("something went wrong");
          }
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("Cancelled");
      }
    });
  };
  const handleGetCourseData = async () => {
    try {
      const getCourse = await axios.get("api/admin/course/get-course");
      if (getCourse?.data?.isCourseFound === true) {
        setGetAllCourseData(getCourse?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };
  const handlePostCourseData = async (e) => {
    e.preventDefault();
    try {
      const setCourseData = await axios.post(
        "api/admin/course/create-course",
        courseData
      );
      if (setCourseData?.data?.isCourseCreated === true) {
        toast.success(setCourseData?.data?.msg);
        window.location.reload();
        handleGetCourseData();
      }
      console.log("getCourse", setCourseData);
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleUpdateCourseData = async (e) => {
    e.preventDefault();
    try {
      const setUpdateCourseData = await axios.put(
        `api/admin/course/update-course/${updateId}`,
        courseUpdateData
      );
      if (setUpdateCourseData.data.isCourseUpdated === true) {
        toast.success(setUpdateCourseData.data.msg);
        handleGetCourseData();
        window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };
  console.log("updateId", updateId);
  useEffect(() => {
    handleGetCourseData();
  }, []);
  return (
    <Layout>
      <form className="d-flex mb-3 my-3">
        <div className="row w-100 justify-content-between g-3">
          <div className="col-lg-5 col-md-6 col-sm-12">
            <div className="input-group ">
              <input
                type="text"
                className="form-control"
                placeholder="Search Address"
                aria-label="Search"
                aria-describedby="button-addon2"
              />
              <button
                className="button-styles"
                type="button"
                id="button-addon2"
              >
                <FiSearch />
              </button>
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-4 col-sm-12">
            <div className="text-end">
              <select className="form-select">
                <option defaultValue value="All">
                  All
                </option>
                <option value="Mega">First Year</option>
                <option value="Large">Second Year</option>
                <option value="Medium">Third Year</option>
              </select>
            </div>
          </div> */}
        </div>
      </form>
      <button
        type="button"
        className="button-styles my-3"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        data-bs-whatever="@mdo"
      >
        <i class="fa-solid fa-plus mx-2"></i>
        Add Course
      </button>
      <div className="table-responsive">
        <div>
          <div className="table-responsive table-box">
            <table className="table table-bordered">
              <thead>
                <tr className="table-head">
                  <th>Course Id</th>
                  <th>Course FullName</th>
                  <th>Course ShortName</th>
                  <th>Course Eligiblity</th>
                  <th>Course Duration Months</th>
                  <th>Course Duration Years</th>
                  <th>createdAt</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {getAllCourseData.map((nft, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{nft.CourseFullName}</td>
                    <td>{nft.CourseShortName}</td>
                    <td>{nft.CourseEligiblity}</td>
                    <td>{nft.CourseDurationMonths}</td>
                    <td>{nft.CourseDurationYears}</td>
                    <td>{nft.createdAt}</td>

                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#editcourse"
                        onClick={() => (
                          setCourseUpdateData({
                            CourseShortName: nft.CourseShortName,
                            CourseFullName: nft.CourseFullName,
                            CourseDurationMonths: nft.CourseDurationMonths,
                            CourseDurationYears: nft.CourseDurationYears,
                            CourseEligiblity: nft.CourseEligiblity,
                          }),
                          setUpdateId(nft._id)
                        )}
                      >
                        <i class="fa-solid fa-pen-to-square mx-2"></i>
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        onClick={() => handleDelete(nft._id)}
                      >
                        <i class="fa-solid fa-trash mx-2"></i>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/*Add modal form */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Course
              </h5>
              <button
                type="button"
                className="btn-close cus-btn-close "
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handlePostCourseData(e)}>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Course ShortName
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={courseData.CourseShortName}
                    onChange={(e) =>
                      handleOnChange("CourseShortName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Course FullName
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={courseData.CourseFullName}
                    onChange={(e) =>
                      handleOnChange("CourseFullName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Course DurationMonths
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={courseData.CourseDurationMonths}
                    onChange={(e) =>
                      handleOnChange("CourseDurationMonths", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Course DurationYears
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={courseData.CourseDurationYears}
                    onChange={(e) =>
                      handleOnChange("CourseDurationYears", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Course Eligiblity
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={courseData.CourseEligiblity}
                    onChange={(e) =>
                      handleOnChange("CourseEligiblity", e.target.value)
                    }
                  />
                </div>
                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-thumbs-up mx-2"></i>Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/*Edit  modal form */}
      <div
        className="modal fade"
        id="editcourse"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Course
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handleUpdateCourseData(e)}>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Course ShortName
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={courseUpdateData.CourseShortName}
                    onChange={(e) =>
                      handleUpdateOnChange("CourseShortName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Course FullName
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={courseUpdateData.CourseFullName}
                    onChange={(e) =>
                      handleUpdateOnChange("CourseFullName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Course DurationMonths
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={courseUpdateData.CourseDurationMonths}
                    onChange={(e) =>
                      handleUpdateOnChange(
                        "CourseDurationMonths",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Course DurationYears
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={courseUpdateData.CourseDurationYears}
                    onChange={(e) =>
                      handleUpdateOnChange(
                        "CourseDurationYears",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Course Eligiblity
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={courseUpdateData.CourseEligiblity}
                    onChange={(e) =>
                      handleUpdateOnChange("CourseEligiblity", e.target.value)
                    }
                  />
                </div>
                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-thumbs-up mx-2"></i>Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer position="top-right" />
    </Layout>
  );
};
export default CoursePageSection;
