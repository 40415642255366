import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { FiSearch } from "react-icons/fi";
import Swal from "sweetalert2";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const SubjectPageSection = () => {
  const [getAllSubjectData, setGetAllSubjectData] = useState([]);
  const [getAllCourseData, setGetAllCourseData] = useState([]);
  const handleDelete = (id) => {
    Swal.fire({
      // title: "Are you sure?",
      text: "Are you sure you won't be deleted?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const setDeleteSubjectData = await axios.delete(
            `api/admin/subject/delete-subject/${id}`
          );
          if (setDeleteSubjectData.data.isSubjectDeleted === true) {
            toast.success(setDeleteSubjectData.data.msg);
            handleGetSubjectData();
            window.location.reload();
          }
        } catch (error) {
          if (error.response.status === 400) {
            const message_400 = error?.response?.data?.message;
            toast.error(message_400);
          } else if (error.response.status === 401) {
            const message_401 = error?.response?.data?.message;
            toast.error(message_401);
          } else if (error.response.status === 404) {
            const message_404 = error?.response?.data?.message;
            console.log("message_404", message_404);
            toast.error(message_404);
          } else if (error.response.status === 500) {
            const message_500 = error?.response?.data?.message;
            toast.error(message_500);
          } else {
            toast.error("something went wrong");
          }
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("Cancelled");
      }
    });
  };
  const [updateId, setUpdateId] = useState("");
  const [subjectData, setSubjectData] = useState({
    CourseID: "",
    SubjectShortName: "",
    SubjectFullName: "",
    SubjectCode: "",
    SubjectMarks: "",
  });

  const [subjectUpdateData, setSubjectUpdateData] = useState({
    CourseID: "",
    SubjectShortName: "",
    SubjectFullName: "",
    SubjectCode: "",
    SubjectMarks: "",
  });

  const handleOnChange = (key, value) => {
    setSubjectData((subjectData) => ({
      ...subjectData,
      [key]: value,
    }));
  };

  const handleUpdateOnChange = (key, value) => {
    setSubjectUpdateData((subjectUpdateData) => ({
      ...subjectUpdateData,
      [key]: value,
    }));
  };

  const handleGetSubjectData = async () => {
    try {
      const getSubject = await axios.get("api/admin/subject/get-subject");
      console.log("getSubject", getSubject);
      if (getSubject?.data?.isSubjectFound === true) {
        setGetAllSubjectData(getSubject?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleGetCourseData = async () => {
    try {
      const getCourse = await axios.get("api/admin/course/get-course");
      if (getCourse?.data?.isCourseFound === true) {
        setGetAllCourseData(getCourse?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handlePostSubjectData = async (e) => {
    e.preventDefault();
    console.log("subjectData", subjectData);
    try {
      const setSubjectData = await axios.post(
        "api/admin/subject/create-subject",
        subjectData
      );
      if (setSubjectData?.data?.isSubujectCreated === true) {
        toast.success(setSubjectData?.data?.msg);
        window.location.reload();
        handleGetSubjectData();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleUpdateSubjectData = async (e) => {
    e.preventDefault();
    try {
      const setUpdateSubjectData = await axios.put(
        `api/admin/subject/update-subject/${updateId}`,
        subjectUpdateData
      );
      if (setUpdateSubjectData.data.isSubjectUpdated === true) {
        toast.success(setUpdateSubjectData.data.msg);
        handleGetSubjectData();
        window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };
  console.log("getAllCourseData", getAllCourseData);
  useEffect(() => {
    handleGetCourseData();
    handleGetSubjectData();
  }, []);
  return (
    <Layout>
      <form className="d-flex mb-3 my-3">
        <div className="row w-100 justify-content-between g-3">
          <div className="col-lg-5 col-md-6 col-sm-12">
            <div className="input-group ">
              <input
                type="text"
                className="form-control"
                placeholder="Search Address"
                aria-label="Search"
                aria-describedby="button-addon2"
              />
              <button
                className="button-styles"
                type="button"
                id="button-addon2"
              >
                <FiSearch />
              </button>
            </div>
          </div>
        </div>
      </form>
      <button
        type="button"
        className="button-styles my-3"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        data-bs-whatever="@mdo"
      >
        <i class="fa-solid fa-plus mx-2"></i>
        Add Subject
      </button>
      <div className="table-responsive">
        <div>
          <div className="table-responsive table-box">
            <table className="table table-bordered">
              <thead>
                <tr className="table-head">
                  <th>#</th>
                  <th>Subject ShortName</th>
                  <th>Subject FullName</th>
                  <th>Subject Code</th>
                  <th>SubjectMarks</th>
                  {/* <th>createdAt</th> */}
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {getAllSubjectData.map((nft, index) => (
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{nft.SubjectShortName}</td>
                    <td>{nft.SubjectFullName}</td>
                    <td>{nft.SubjectCode}</td>
                    <td>{nft.SubjectMarks}</td>
                    {/* <td>{nft.createdAt}</td> */}
                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#editsubject"
                        onClick={() => (
                          setSubjectUpdateData({
                            CourseID: nft.CourseID,
                            SubjectShortName: nft.SubjectShortName,
                            SubjectFullName: nft.SubjectFullName,
                            SubjectCode: nft.SubjectCode,
                            SubjectMarks: nft.SubjectMarks,
                          }),
                          setUpdateId(nft._id)
                        )}
                      >
                        <i class="fa-solid fa-pen-to-square mx-2"></i>
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        onClick={() => handleDelete(nft._id)}
                      >
                        <i class="fa-solid fa-trash mx-2"></i>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* add modal form */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Subject
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handlePostSubjectData(e)}>
                <div className="mb-3">
                  <select
                    className="form-control"
                    onChange={(e) => handleOnChange("CourseID", e.target.value)}
                  >
                    <option value="">Select Course</option>
                    {getAllCourseData?.map((value, key) => {
                      return (
                        <option value={value?._id}>
                          {value?.CourseShortName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Subject ShortName
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={subjectData.SubjectShortName}
                    onChange={(e) =>
                      handleOnChange("SubjectShortName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Subject FullName
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={subjectData.SubjectFullName}
                    onChange={(e) =>
                      handleOnChange("SubjectFullName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Subject Marks
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={subjectData.SubjectMarks}
                    onChange={(e) =>
                      handleOnChange("SubjectMarks", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Subject Code
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={subjectData.SubjectCode}
                    onChange={(e) =>
                      handleOnChange("SubjectCode", e.target.value)
                    }
                  />
                </div>
                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-thumbs-up mx-2"></i>Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* edit modal form */}
      <div
        className="modal fade"
        id="editsubject"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Subject
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handleUpdateSubjectData(e)}>
                <div className="mb-3">
                  {/* <label htmlFor="recipient-name" className="col-form-label">
                    Select Course Name
                  </label> */}
                  <select
                    className="form-control"
                    onChange={(e) =>
                      handleUpdateOnChange("CourseID", e.target.value)
                    }
                  >
                    <option value="">Select Course</option>
                    {getAllCourseData?.map((value, key) => {
                      return (
                        <option value={value?._id}>
                          {value?.CourseShortName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Subject ShortName
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={subjectUpdateData.SubjectShortName}
                    onChange={(e) =>
                      handleUpdateOnChange("SubjectShortName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Subject FullName
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={subjectUpdateData.SubjectFullName}
                    onChange={(e) =>
                      handleUpdateOnChange("SubjectFullName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Subject Marks
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={subjectUpdateData.SubjectMarks}
                    onChange={(e) =>
                      handleUpdateOnChange("SubjectMarks", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Subject Code
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={subjectUpdateData.SubjectCode}
                    onChange={(e) =>
                      handleUpdateOnChange("SubjectCode", e.target.value)
                    }
                  />
                </div>
                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-thumbs-up mx-2"></i>Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default SubjectPageSection;
