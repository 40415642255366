import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { FiSearch } from "react-icons/fi";
import Swal from "sweetalert2";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "./styles.css";
const PaymentPageSection = () => {
  const [getAllpaymentData, setGetAllpaymentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [paymentData, setpaymentData] = useState({
    PaymentAmount: "",
    PaymentScreenShot: "",
    IfscCode: "",
    PaymentRefNumber: "",
    Status: "",
  });

  const handleDelete = (id) => {
    Swal.fire({
      text: "Are you sure you won't be deleted?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const setDeletepaymentData = await axios.delete(
            `api/admin/delete-payment/${id}`
          );
          if (setDeletepaymentData.data.isPaymentDeleted === true) {
            toast.success(setDeletepaymentData.data.msg);
            handleGetpaymentData();
          }
        } catch (error) {
          if (error.response.status === 400) {
            const message_400 = error?.response?.data?.message;
            toast.error(message_400);
          } else if (error.response.status === 401) {
            const message_401 = error?.response?.data?.message;
            toast.error(message_401);
          } else if (error.response.status === 404) {
            const message_404 = error?.response?.data?.message;
            console.log("message_404", message_404);
            toast.error(message_404);
          } else if (error.response.status === 500) {
            const message_500 = error?.response?.data?.message;
            toast.error(message_500);
          } else {
            toast.error("something went wrong");
          }
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("Cancelled");
      }
    });
  };

  const handleGetpaymentData = async () => {
    try {
      const getPayment = await axios.get("api/admin/get-payment-request");
      if (getPayment?.status === 200) {
        setGetAllpaymentData(getPayment?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleChangeStatus = async (event, data) => {
    event.preventDefault();
    const value = event.target.value;
    // console.log({
    //   value: value,
    //   data: data,
    // });
    const ajaxData = {
      PaymentAmount: data?.PaymentAmount,
      PaymentScreenShot: data?.PaymentScreenShot,
      IfscCode: data?.IfscCode,
      PaymentRefNumber: data?.PaymentRefNumber,
      Status: value,
    };
    try {
      const setpaymentData = await axios.put(
        `api/admin/update-payment-request/${data?._id}`,
        ajaxData
      );
      if (setpaymentData?.data?.isRequestUpdated === true) {
        toast.success(setpaymentData?.data?.msg);
        window.location.reload();
        handleGetpaymentData();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  useEffect(() => {
    handleGetpaymentData();
  }, []);
  return (
    <Layout>
      <form className="d-flex mb-3 my-3">
        <div className="row w-100 justify-content-between g-3">
          <div className="col-lg-5 col-md-6 col-sm-12">
            <div className="input-group ">
              <input
                type="text"
                className="form-control"
                placeholder="Search Address"
                aria-label="Search"
                aria-describedby="button-addon2"
              />
              <button
                className="button-styles"
                type="button"
                id="button-addon2"
              >
                <FiSearch />
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="table-responsive">
        <div>
          <div className="table-responsive table-box">
            <table className="table table-bordered">
              <thead>
                <tr className="table-head">
                  <th>Payment Id</th>
                  <th>CenterId</th>
                  <th>PaymentAmount</th>
                  <th>PaymentRefNumber</th>
                  <th>Status</th>
                  <th>Image</th>
                  <th>createdAt</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {getAllpaymentData.map((nft, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{nft.CenterId}</td>
                    <td>{nft.PaymentAmount}</td>
                    <td>{nft.PaymentRefNumber}</td>
                    <td
                      className={`fw-bold ${
                        nft.Status === 0
                          ? "text-warning "
                          : nft.Status === 1
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {nft.Status === 0
                        ? "Pending"
                        : nft.Status === 1
                        ? "Active"
                        : "Reject"}
                    </td>
                    <td>
                      <div className="tbl-img">
                        <img
                          src={`https://backend.nileshdawande.in/images/${nft?.PaymentScreenShot}`}
                          alt="img1"
                        />
                      </div>
                    </td>
                    <td>{nft.createdAt}</td>
                    <td>
                      <select
                        className="button-styles"
                        onChange={(event) => handleChangeStatus(event, nft)}
                      >
                        <option>Set Status</option>
                        <option value="1">Active</option>
                        <option value="0">Pending</option>
                        <option value="2">Reject</option>
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </Layout>
  );
};
export default PaymentPageSection;
