import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { FiSearch } from "react-icons/fi";
import Swal from "sweetalert2";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const TimeTablePageSection = () => {
  const [getAllCourseData, setGetAllCourseData] = useState([]);
  const [courseValue, setCourseValue] = useState([]);
  const [updateId, setUpdateId] = useState("");
  const [subjectData, setSubjectData] = useState([]);
  const [subjectUpdateData, setSubjectUpdateData] = useState([]);
  const [allSubjectData, setGetAllSubjectData] = useState([]);
  const [courseId, setCourseId] = useState("");
  const [courseUpdateData, setCourseUpdateData] = useState({
    CourseShortName: "",
    CourseFullName: "",
    CourseDurationMonths: "",
    CourseDurationYears: "",
    CourseEligiblity: "",
  });

  const handleOnChange = (key, value, index) => {
    var data = [];
    allSubjectData.forEach((element, no) => {
      if (index == no) {
        data.push({
          ...element,
          [key]: value,
        });
      } else {
        data.push({ ...element });
      }
    });
    setGetAllSubjectData(data);
  };

  const handleUpdateOnChange = (key, value, index) => {
    var data = [];
    subjectUpdateData.forEach((element, no) => {
      if (index == no) {
        data.push({
          ...element,
          [key]: value,
        });
      } else {
        data.push({ ...element });
      }
    });
    setSubjectUpdateData(data);
  };

  const handleDelete = (id) => {
    Swal.fire({
      text: "Are you sure you won't be deleted?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const setDeleteStudentData = await axios.delete(
            `api/admin/delete-time-table/${id}`
          );
          if (setDeleteStudentData?.status === 200) {
            toast.success(setDeleteStudentData.data.msg);
            handleGetTimeTableData();
            window.location.reload();
          }
        } catch (error) {
          if (error.response.status === 400) {
            const message_400 = error?.response?.data?.message;
            toast.error(message_400);
          } else if (error.response.status === 401) {
            const message_401 = error?.response?.data?.message;
            toast.error(message_401);
          } else if (error.response.status === 404) {
            const message_404 = error?.response?.data?.message;
            console.log("message_404", message_404);
            toast.error(message_404);
          } else if (error.response.status === 500) {
            const message_500 = error?.response?.data?.message;
            toast.error(message_500);
          } else {
            toast.error("something went wrong");
          }
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("Cancelled");
      }
    });
  };
  const handleGetTimeTableData = async () => {
    try {
      const getTimeTable = await axios.get("api/admin/get-time-table");
      if (getTimeTable?.status === 200) {
        setGetAllCourseData(getTimeTable?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };
  const handlePostCourseData = async (e) => {
    e.preventDefault();
    try {
      const setSubjectData = await axios.post("api/admin/create-time-table", {
        CourseId: courseId,
        Subjects: allSubjectData,
      });
      if (setSubjectData?.status === 200) {
        toast.success(setSubjectData?.data?.msg);
        window.location.reload();
        handleGetTimeTableData();
      }
      console.log("getTimeTable", setSubjectData);
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleUpdateSubjectData = async (e) => {
    debugger;
    e.preventDefault();
    try {
      const setUpdateSubjectData = await axios.put(
        `api/admin/update-time-table/${updateId}`,
        { Subjects: subjectUpdateData }
      );
      if (setUpdateSubjectData.status === 200) {
        toast.success(setUpdateSubjectData.data.msg);
        handleGetTimeTableData();
        window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleGetCourseData = async () => {
    try {
      const getCourse = await axios.get("api/admin/course/get-course");
      if (getCourse?.data?.isCourseFound === true) {
        setCourseValue(getCourse?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  // for get subject

  const handleGetMarksData = async (id) => {
    try {
      const getSubject = await axios.get(`api/admin/get-subject-details/${id}`);
      console.log("getSubject", getSubject);
      if (getSubject?.status === 200) {
        setGetAllSubjectData(getSubject?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  useEffect(() => {
    handleGetTimeTableData();
    handleGetCourseData();
  }, []);
  return (
    <Layout>
      <form className="d-flex mb-3 my-3">
        <div className="row w-100 justify-content-between g-3">
          <div className="col-lg-5 col-md-6 col-sm-12">
            <div className="input-group ">
              <input
                type="text"
                className="form-control"
                placeholder="Search Address"
                aria-label="Search"
                aria-describedby="button-addon2"
              />
              <button
                className="button-styles"
                type="button"
                id="button-addon2"
              >
                <FiSearch />
              </button>
            </div>
          </div>
        </div>
      </form>
      <button
        type="button"
        className="button-styles my-3"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        data-bs-whatever="@mdo"
      >
        <i class="fa-solid fa-plus mx-2"></i>
        Create Time Table
      </button>
      <div className="table-responsive">
        <div>
          <div className="table-responsive table-box">
            <table className="table table-bordered">
              <thead>
                <tr className="table-head">
                  <th>Course Id</th>
                  <th>Subject</th>

                  <th>createdAt</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {getAllCourseData.map((nft, index) => (
                  <tr key={index}>
                    <td>{nft?.courseName}</td>
                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#subjectModal"
                        data-bs-whatever="@mdo"
                        onClick={() => setSubjectData(nft?.Subjects)}
                      >
                        <i class="fa-solid fa-trash mx-2"></i>
                        Subject
                      </button>
                    </td>
                    <td>{nft.createdAt}</td>

                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#editcourse"
                        onClick={() => (
                          setSubjectUpdateData(nft?.Subjects),
                          setUpdateId(nft._id)
                        )}
                      >
                        <i class="fa-solid fa-pen-to-square mx-2"></i>
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        onClick={() => handleDelete(nft._id)}
                      >
                        <i class="fa-solid fa-trash mx-2"></i>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/*Add modal form */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Create Time Table
              </h5>
              <button
                type="button"
                className="btn-close cus-btn-close "
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handlePostCourseData(e)}>
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="mb-3">
                          <label
                            htmlFor="recipient-name"
                            className="col-form-label"
                          >
                            Course
                          </label>
                          <select
                            className="form-control"
                            onChange={(e) => (
                              setCourseId(e.target.value),
                              handleGetMarksData(e.target.value)
                            )}
                          >
                            <option value="" defaultChecked>
                              ---Select---
                            </option>
                            {courseValue?.map((value, key) => {
                              return (
                                <option value={value?._id}>
                                  {value?.CourseShortName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    {allSubjectData?.map((value, index) => {
                      return (
                        <>
                          <div className="row">
                            <div className="col-md-6 col-12">
                              <div className="mb-2">
                                <label
                                  htmlFor="recipient-name"
                                  className="col-form-label"
                                >
                                  SubjectFullName
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={value?.SubjectFullName}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-md-2 col-12">
                              <div className="mb-2">
                                <label
                                  htmlFor="recipient-name"
                                  className="col-form-label"
                                >
                                  StartDate
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  value={value?.StartDate}
                                  onChange={(e) =>
                                    handleOnChange(
                                      "StartDate",
                                      e.target.value,
                                      index
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-2 col-12">
                              <div className="mb-2">
                                <label
                                  htmlFor="recipient-name"
                                  className="col-form-label"
                                >
                                  StartTime
                                </label>
                                <input
                                  type="time"
                                  className="form-control"
                                  value={value?.StartTime}
                                  onChange={(e) =>
                                    handleOnChange(
                                      "StartTime",
                                      e.target.value,
                                      index
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-2 col-12">
                              <div className="mb-2">
                                <label
                                  htmlFor="recipient-name"
                                  className="col-form-label"
                                >
                                  EndTime
                                </label>
                                <input
                                  type="time"
                                  className="form-control"
                                  value={value?.EndTime}
                                  onChange={(e) =>
                                    handleOnChange(
                                      "EndTime",
                                      e.target.value,
                                      index
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>

                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-thumbs-up mx-2"></i>Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/*Edit  modal form */}
      <div
        className="modal fade"
        id="editcourse"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Time Table
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              {/* <div className="table-responsive">
                <div>
                  <div className="table-responsive table-box">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-head">
                          <th>StartDate</th>
                          <th>StartTime</th>
                          <th>EndTime</th>
                          <th>SubjectCode</th>
                          <th>SubjectFullName</th>
                          <th>SubjectShortName</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subjectUpdateData.map((nft, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                type="date"
                                value={new Date("2022-03-25")}
                              />
                            </td>
                            <td>
                              <input type="time" value={nft?.StartTime} />
                            </td>
                            <td>
                              <input type="time" value={nft?.EndTime} />
                            </td>
                            <td>{nft?.SubjectCode}</td>
                            <td>{nft?.SubjectFullName}</td>
                            <td>{nft?.SubjectShortName}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> */}

              <form onSubmit={(e) => handleUpdateSubjectData(e)}>
                <div className="row">
                  <div className="col-12">
                    {subjectUpdateData?.map((value, index) => {
                      return (
                        <>
                          <div className="row">
                            <div className="col-md-6 col-12">
                              <div className="mb-2">
                                <label
                                  htmlFor="recipient-name"
                                  className="col-form-label"
                                >
                                  SubjectFullName
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={value?.SubjectFullName}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-md-2 col-12">
                              <div className="mb-2">
                                <label
                                  htmlFor="recipient-name"
                                  className="col-form-label"
                                >
                                  StartDate
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  value={value?.StartDate}
                                  onChange={(e) =>
                                    handleUpdateOnChange(
                                      "StartDate",
                                      e.target.value,
                                      index
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-2 col-12">
                              <div className="mb-2">
                                <label
                                  htmlFor="recipient-name"
                                  className="col-form-label"
                                >
                                  StartTime
                                </label>
                                <input
                                  type="time"
                                  className="form-control"
                                  value={value?.StartTime}
                                  onChange={(e) =>
                                    handleUpdateOnChange(
                                      "StartTime",
                                      e.target.value,
                                      index
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-2 col-12">
                              <div className="mb-2">
                                <label
                                  htmlFor="recipient-name"
                                  className="col-form-label"
                                >
                                  EndTime
                                </label>
                                <input
                                  type="time"
                                  className="form-control"
                                  value={value?.EndTime}
                                  onChange={(e) =>
                                    handleUpdateOnChange(
                                      "EndTime",
                                      e.target.value,
                                      index
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>

                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-thumbs-up mx-2"></i>Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/*Subject Details modal */}
      <div
        className="modal fade"
        id="subjectModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Subject Details
              </h5>
              <button
                type="button"
                className="btn-close cus-btn-close "
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <div>
                  <div className="table-responsive table-box">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-head">
                          <th>StartDate</th>
                          <th>StartTime</th>
                          <th>EndTime</th>
                          <th>SubjectCode</th>
                          <th>SubjectFullName</th>
                          <th>SubjectShortName</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subjectData.map((nft, index) => (
                          <tr key={index}>
                            <td>{nft?.StartDate}</td>
                            <td>{nft?.StartTime}</td>
                            <td>{nft.EndTime}</td>

                            <td>{nft?.SubjectCode}</td>
                            <td>{nft?.SubjectFullName}</td>
                            <td>{nft?.SubjectShortName}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer position="top-right" />
    </Layout>
  );
};
export default TimeTablePageSection;
