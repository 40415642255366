import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { FiSearch } from "react-icons/fi";
import Swal from "sweetalert2";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import barCode from "../../../assets/images/barcode.png";
import MarksheerLogo from "../../../assets/images/marksheetLogo.png";
import Signature from "../../../assets/images/Signature.png";
import QRCode from "react-qr-code";
import "./styles.css";
import numberToWords from "number-to-words";
import Paginate from "../../Sections/Paginate/index";
import Loader from "../Loader";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
const InvoicePageSection = () => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [getAllStudentData, setgetAllStudentData] = useState([]);
  const [updateId, setUpdateId] = useState("");
  const [getSubAdminData, setGetSubAdminData] = useState([]);
  const [allMarksheetData, setAllMarksheetData] = useState([]);
  const [getAllCourseData, setGetAllCourseData] = useState([]);
  const [getAllmarksheetData, setGetAllmarksheetData] = useState([]);
  const [getAllMarksData, setGetAllMarksData] = useState([]);
  const [marksData, setMarksData] = useState([]);
  const [defaultCourseId, setDefaultCourseId] = useState(null);
  const [defaultSubjectData, setDefalutSubjectData] = useState([]);
  const [marksheetStudentId, setMarksheetStudentId] = useState("");
  const [pagination, setPagination] = useState({});
  const [marksheetUpdateData, setmarksheetUpdateData] = useState({
    RollNumber: "",
    EnrollmentNumber: "",
    DmcNumber: "",
    DateOfIssue: "",
  });
  const [updateStudentData, setUpdateStudentData] = useState({
    student: {
      StudentFirstName: "",
      StudentLastName: "",
      StudentEmail: "",
      StudentPicture: "",
      StudentMobile: "",
      StudentDob: "",
      StudentFatherName: "",
      StudentMotherName: "",
      StudentSessionStart: "",
      StudentSessionEnd: "",
    },
    subjects: [
      {
        SubjectShortName: "",
        SubjectFullName: "",
        SubjectCode: "",
        SubjectMarks: "",
        Marks: "",
      },
    ],
    course: {
      _id: "",
    },
    center: {
      _id: "",
    },
    marksheetType: {
      _id: "",
    },
  });

  const [pageCount, setPageCount] = useState(0);
  const [pagenumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(5);
 


  const handlePageClick = async (event) => {
    setPageNumber(event.selected + 1);
    handleGetStudentData(event.selected + 1, pageLimit);
  };
  const handleOnChange = (key, value, subIndex) => {
    setUpdateStudentData((prevState) => {
      let updatedState = { ...prevState }; // Copy previous state
  
      if (key.includes("student")) {
        const studentKey = key.split(".")[1];
        updatedState.student = {
          ...prevState.student,
          [studentKey]: value,
        };
      } else if (key.includes("subject")) {
        updatedState.subjects = prevState.subjects.map((itemData, keynum) => {
          if (keynum === subIndex) {
            return {
              ...itemData,
              Marks: value,
            };
          }
          return itemData;
        });
      } else if (key.includes("course")) {
        const courseKey = key.split(".")[1];
        updatedState.course = {
          ...prevState.course,
          [courseKey]: value,
        };
      } else if (key.includes("center")) {
        const centerKey = key.split(".")[1];
        updatedState.center = {
          ...prevState.center,
          [centerKey]: value,
        };
      } else if (key.includes("marksheetType")) {
        const marksheetTypeKey = key.split(".")[1];
        updatedState.marksheetType = {
          ...prevState.marksheetType,
          [marksheetTypeKey]: value,
        };
      }
      return { ...updatedState };
    });
  };


  const QrData = `Registration No :${
    allMarksheetData?.student?.EnrollmentNumber
  }
   Name : ${allMarksheetData?.student?.FirstName} ${
    allMarksheetData?.student?.LastName
  }
   NFSSC Grand: ${"A.Verified*"}`;

  const handleUpdateOnChange = (key, value) => {
    setmarksheetUpdateData((marksheetUpdateData) => ({
      ...marksheetUpdateData,
      [key]: value,
    }));
  };

  const handleGetStudentData = async (pageN, pageL) => {
    setLoading(true);
    try {
      const getStudent = await axios.get(
        `api/admin/get-marksheet-request/${pageN}/${pageL}`
      );
      if (getStudent?.status === 200) {
        setgetAllStudentData(getStudent?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };

  const handleInvoiceData = async (id) => {
    try {
      const ajax = await axios.get(`api/admin/generate-marksheet/${id}`);

      if (ajax?.status === 200) {
        var singleData = ajax?.data?.data;
        setAllMarksheetData(singleData[0]);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleUpdateMarksData = async (e) => {
    debugger
    e.preventDefault();
    try {
      const updateMarkssheetData = await axios.put(
        `api/admin/update-marksheet-details/${updateId}`,
        marksheetUpdateData
      );
      if (updateMarkssheetData?.status === 200) {
        toast.success(updateMarkssheetData.data.msg);
        handleGetStudentData(pagenumber, pageLimit);
        // window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handlegetSessionDate = (originalDateString) => {
    // const originalDateString = '2024-02-15T00:00:00.000Z';
    const originalDate = new Date(originalDateString);
    const month = originalDate.toLocaleString("default", { month: "short" });
    const year = originalDate.getFullYear();
    const formattedDate = `${month}. ${year}`;
    return formattedDate;
  };

  const handlegetMonth = (startDateString, endDateString) => {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    const diffMonths =
      (endDate.getFullYear() - startDate.getFullYear()) * 12 +
      (endDate.getMonth() - startDate.getMonth());
    return diffMonths;
  };
  const data =
    allMarksheetData?.student?.total == undefined
      ? 0
      : allMarksheetData?.student?.total;

  const handleDelete = (id) => {
    Swal.fire({
      text: "Are you sure you won't be deleted?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const setDeletesubadmoinData = await axios.delete(
            `api/admin/delete-student-details/${id}`
          );
          if (setDeletesubadmoinData?.status === 200) {
            toast.success(setDeletesubadmoinData.data.msg);
            handleGetStudentData(pagenumber, pageLimit);
          }
        } catch (error) {
          if (error.response.status === 400) {
            const message_400 = error?.response?.data?.message;
            toast.error(message_400);
          } else if (error.response.status === 401) {
            const message_401 = error?.response?.data?.message;
            toast.error(message_401);
          } else if (error.response.status === 404) {
            const message_404 = error?.response?.data?.message;
            console.log("message_404", message_404);
            toast.error(message_404);
          } else if (error.response.status === 500) {
            const message_500 = error?.response?.data?.message;
            toast.error(message_500);
          } else {
            toast.error("something went wrong");
          }
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("Cancelled");
      }
    });
  };

  const handelGetStudentData = async (id) => {
    try {
      const getStudentDetails = await axios.get(
        `api/admin/get-student-details/${id}`
      );

      if (getStudentDetails?.status === 200) {
        const { student, center, course, subjects, marksheetType } =
          getStudentDetails?.data?.data;
        setDefaultCourseId(course?.CourseId);
        setDefalutSubjectData(subjects);
        setUpdateStudentData({
          student: {
            StudentFirstName: student?.StudentFirstName,
            StudentLastName: student?.StudentLastName,
            StudentEmail: student?.StudentEmail,
            StudentPicture: student?.StudentPicture,
            StudentMobile: student?.StudentMobile,
            StudentDob: student?.StudentDob,
            StudentFatherName: student?.StudentFatherName,
            StudentMotherName: student?.StudentMotherName,
            StudentSessionStart: student?.StudentSessionStart,
            StudentSessionEnd: student?.StudentSessionEnd,
          },
          subjects: [...subjects],
          course: {
            _id: course?.CourseId,
          },
          center: {
            _id: center?.CenterId,
          },
          marksheetType: {
            _id: marksheetType?._id,
          },
        });
        // setgetAllStudentData(getStudentDetails?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Student FirstName
              </label>
              <input
                type="text"
                className="form-control"
                value={updateStudentData?.student?.StudentFirstName}
                onChange={(e) =>
                  handleOnChange("student.StudentFirstName", e.target.value, "")
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Student LastName
              </label>
              <input
                type="text"
                className="form-control"
                value={updateStudentData?.student?.StudentLastName}
                onChange={(e) =>
                  handleOnChange("student.StudentLastName", e.target.value, "")
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Student Mobile No
              </label>
              <input
                type="number"
                className="form-control"
                value={updateStudentData?.student?.StudentMobile}
                onChange={(e) =>
                  handleOnChange("student.StudentMobile", e.target.value, "")
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Student Email
              </label>
              <input
                type="email"
                className="form-control"
                value={updateStudentData?.student?.StudentEmail}
                onChange={(e) =>
                  handleOnChange("student.StudentEmail", e.target.value, "")
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Student Date of Birth
              </label>
              <input
                type="date"
                className="form-control"
                value={updateStudentData?.student?.StudentDob}
                onChange={(e) =>
                  handleOnChange("student.StudentDob", e.target.value, "")
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Student Picture
              </label>
              <input
                type="file"
                accept="image/jpg, image/jpeg, image/png"
                // onChange={(e) => handleImageChange(e)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Student Father Name
              </label>
              <input
                type="text"
                className="form-control"
                value={updateStudentData?.student?.StudentFatherName}
                onChange={(e) =>
                  handleOnChange(
                    "student.StudentFatherName",
                    e.target.value,
                    ""
                  )
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Student Mother Name
              </label>
              <input
                type="text"
                className="form-control"
                value={updateStudentData?.student?.StudentMotherName}
                onChange={(e) =>
                  handleOnChange(
                    "student.StudentMotherName",
                    e.target.value,
                    ""
                  )
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Student Session Start
              </label>
              <input
                type="date"
                className="form-control"
                value={updateStudentData?.student?.StudentSessionStart}
                onChange={(e) =>
                  handleOnChange(
                    "student.StudentSessionStart",
                    e.target.value,
                    ""
                  )
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Student Session End
              </label>
              <input
                type="date"
                className="form-control"
                value={updateStudentData?.student?.StudentSessionEnd}
                onChange={(e) =>
                  handleOnChange(
                    "student.StudentSessionEnd",
                    e.target.value,
                    ""
                  )
                }
              />
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Marksheet Type
              </label>

              <select
                className="form-control"
                onChange={(e) =>
                  handleOnChange("marksheetType._id", e.target.value, "")
                }
              >
                <option value="" defaultChecked>
                  ---Select---
                </option>
                {getAllmarksheetData?.map((value, key) => {
                  return <option value={value?._id}>{value?.shortName}</option>;
                })}
              </select>
            </div>

            {/* <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Center
              </label>

              <select
                className="form-control"
                onChange={(e) =>
                  handleOnChange("center._id", e.target.value, "")
                }
              >
                <option value="" defaultChecked>
                  ---Select---
                </option>
                {getSubAdminData?.map((value, key) => {
                  return (
                    <option value={value?._id}>{value?.centerDist}</option>
                  );
                })}
              </select>
            </div> */}

            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Course
              </label>
              <select
                className="form-control"
                defaultValue={defaultCourseId}
                onChange={(e) => (
                  handleOnChange("course._id", e.target.value, ""),
                  handleGetSubject(e.target.value)
                )}
              >
                <option value="" defaultChecked>
                  ---Select---
                </option>
                {getAllCourseData?.map((value, key) => {
                  return (
                    <option value={value?._id}>{value?.CourseShortName}</option>
                  );
                })}
              </select>
            </div>
            {updateStudentData?.subjects?.map((value, subIndex) => {
              return (
                <div className="row">
                  <div className="col-12">
                    <div className="row my-2">
                      <div className="col-8">
                        <label
                          htmlFor="recipient-name"
                          className="col-form-label fw-bold"
                        >
                          Subject Full Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={value?.SubjectFullName}
                          disabled
                        />
                      </div>
                      <div className="col-4">
                        <label
                          htmlFor="recipient-name"
                          className="col-form-label fw-bold"
                        >
                          Set Marks
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={value?.Marks}
                          onChange={(e) =>
                            handleOnChange(
                              "subject.Marks",
                              e.target.value,
                              subIndex
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        );
      // Add more cases for additional steps as needed
      default:
        return null;
    }
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updateData = await axios.put(
        `api/admin/update-student-details/${marksheetStudentId}`,
        updateStudentData
      );
      if (updateData?.status === 200) {
        toast.success(updateData.data.message);
        window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };
  const handleGetCourseData = async () => {
    try {
      const getCourse = await axios.get("api/admin/course/get-course");
      if (getCourse?.data?.isCourseFound === true) {
        console.log("getCourse?.data?.data",getCourse?.data?.data);
        
        setGetAllCourseData(getCourse?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleGetmarksheetData = async () => {
    try {
      const getMarksheet = await axios.get("api/admin/get-marksheettype");
      if (getMarksheet?.data?.ismarksheetTypeFound === true) {
        setGetAllmarksheetData(getMarksheet?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleGetSubject = async (courrseId) => {
    try {
      const getSubjectData = await axios.get(
        `api/admin/get-subject-details/${courrseId}`
      );
      if (getSubjectData?.status === 200) {
        setGetAllMarksData(getSubjectData?.data?.data);
        setMarksData(getSubjectData?.data?.data);
        if (courrseId == defaultCourseId) {
          setUpdateStudentData({
            ...updateStudentData,
            subjects: defaultSubjectData,
          });
        } else {
          var newData = getSubjectData?.data?.data.map((item, index) => {
            return {
              ...item,
              Marks: "0",
            };
          });
          setUpdateStudentData({ ...updateStudentData, subjects: newData , course: {
            _id: courrseId,
          },});
        }
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };
  const handleGetsubAminData = async () => {
    try {
      const getSubAdmin = await axios.get("api/admin/center/get-child-admin");
      if (getSubAdmin?.status === 200) {
        setGetSubAdminData(getSubAdmin?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleCountPagination = async () => {
    try {
      const getCountNo = await axios.get(
        "api/admin/get-marksheet-request-count"
      );
      if (getCountNo?.status === 200) {
        let x = getCountNo?.data?.count / pageLimit;
        if (Number.isInteger(x)) {
          setPageCount(x);
        } else {
          x = x + 1;
          x = parseInt(x);
          setPageCount(x);
        }
      }
    } catch (error) {
      console.log(error);
      // if (error.response.status === 400) {
      //   const message_400 = error?.response?.data?.message;
      //   toast.error(message_400);
      // } else if (error.response.status === 401) {
      //   const message_401 = error?.response?.data?.message;
      //   toast.error(message_401);
      // } else if (error.response.status === 404) {
      //   const message_404 = error?.response?.data?.message;
      //   console.log("message_404", message_404);
      //   toast.error(message_404);
      // } else if (error.response.status === 500) {
      //   const message_500 = error?.response?.data?.message;
      //   toast.error(message_500);
      // } else {
      //   toast.error("something went wrong");
      // }
    }
  };

  // for marksheet
  const handleDownloadPDF = () => {
    const input = document.getElementById("pdf-content");
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(
          imgData,
          "PNG",
          0,
          0,
          pdf.internal.pageSize.getWidth(),
          pdf.internal.pageSize.getHeight()
        );
        pdf.save("downloaded-marksheet.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };
  const handlePrint = () => {
    window.print(); // This triggers the print dialog
  };
  // end

  // for certificate
  const handleDownloadCertificate = () => {
    const input = document.getElementById("cer-pdf-content");
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(
          imgData,
          "PNG",
          0,
          0,
          pdf.internal.pageSize.getWidth(),
          pdf.internal.pageSize.getHeight()
        );
        pdf.save("downloaded-certificate.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };
  const handleCertificatePrint = () => {
    window.print(); // This triggers the print dialog
  };
  // end

  const handleSearch = async () => {
    setLoading(true);
    try {
      const searchData = await axios.get(
        `api/admin/search-marksheet-rollnumber/${searchText}`
      );
      if (searchData?.status === 200) {
        setgetAllStudentData(searchData?.data?.data);
        setSearchText("");
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else if (error.response.status === 501) {
        console.log("501");
        const message_501 = error?.response?.data?.msg;
        toast.error(message_501);
      } else {
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetStudentData(pagenumber, pageLimit);
    handleGetCourseData();
    handleGetmarksheetData();
    handleGetsubAminData();
    handleCountPagination();
  }, []);

  return (
    <Layout>
      {loading && <Loader loading={loading} />}
      <form className="d-flex mb-3 my-3">
        <div className="row w-100 justify-content-between g-3">
          <div className="col-lg-5 col-md-6 col-sm-12">
            <div className="input-group ">
              <input
                type="text"
                className="form-control"
                placeholder="Search Address"
                aria-label="Search"
                aria-describedby="button-addon2"
                onChange={(e) => setSearchText(e.target.value)}
              />
              <button
                className="button-styles"
                type="button"
                id="button-addon2"
                onClick={() => handleSearch()}
              >
                <FiSearch />
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="table-responsive">
        <div>
          <div className="table-responsive table-box">
            <table className="table table-bordered">
              <thead>
                <tr className="table-head">
                  {/* <th>No Id</th> */}
                  <th>FirstName</th>
                  <th>LastName</th>
                  <th>Email</th>
                  <th>Center Name</th>
                  <th>Center State</th>
                  <th>Course Name</th>
                  {/* <th>Edit</th> */}
                  <th>Marksheet</th>
                  <th>Certificate </th>
                  <th>Edit </th>
                  <th>Delete </th>
                </tr>
              </thead>
              <tbody>
                {getAllStudentData.map((nft, index) => (
                  <tr key={index}>
                    {/* <td>{index + 1}</td> */}
                    <td>{nft?.student?.FirstName}</td>
                    <td>{nft.student?.LastName}</td>
                    <td>{nft.student?.Email}</td>
                    <td>{nft.center.Name}</td>
                    <td>{nft.center.State}</td>
                    <td>{nft.course.Name}</td>
                    {/* <td>
                      <button
                        className="button-styles"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#editcourse"
                        onClick={() => setUpdateId(nft?._id)}
                      >
                        <i class="fa-solid fa-pen-to-square mx-2"></i>
                        Add Roll No
                      </button>
                    </td> */}

                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => handleInvoiceData(nft?._id)}
                      >
                        <i class="fa-solid fa-pen-to-square mx-2"></i>
                        Marksheet
                      </button>
                    </td>
                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#certificateModal"
                        onClick={() => handleInvoiceData(nft?._id)}
                      >
                        <i class="fa-solid fa-pen-to-square mx-2"></i>
                        Certificate
                      </button>
                    </td>
                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#editModal"
                        onClick={() => (
                          handelGetStudentData(nft?._id),
                          setMarksheetStudentId(nft?._id)
                        )}
                      >
                        <i class="fa-solid fa-pen-to-square mx-2"></i>
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                        className="button-styles"
                        onClick={() => handleDelete(nft._id)}
                      >
                        <i class="fa-solid fa-pen-to-square mx-2"></i>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* pagenation  */}
      <div className="container">
        <Paginate pageCount={pageCount} handlePageClick={handlePageClick} />
      </div>

      {/*Marksheet modal form */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="row justify-content-end">
              <div className="col-2 text-md-right mb-3 mb-md-0">
                <button
                  className="hide-on-print form-button-styles"
                  onClick={handleDownloadPDF}
                >
                  <i className="fa-solid fa-file-pdf mx-2"></i>
                  PDF
                </button>
              </div>
              <div className="col-2 text-md-right">
                <button
                  className="hide-on-print form-button-styles"
                  onClick={handlePrint}
                >
                  <i className="fa-solid fa-print mx-2"></i>
                  Print
                </button>
              </div>
            </div>
            <div className="modal-body" id="pdf-content">
              <section>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <form>
                        <div className="marksheet-box">
                          <div className="row">
                            <div className="col-sm-6 enrolmentNo txt-cntr">
                              <p className="mb-0 text-start">
                                <strong>
                                  DMC No :{" "}
                                  {allMarksheetData?.student?.DmcNumber}
                                </strong>
                              </p>
                            </div>

                            <div className="col-sm-6 txt-cntr">
                              <p className="mb-0 text-end">
                                <strong>
                                  Enrollment No :{" "}
                                  {allMarksheetData?.student?.EnrollmentNumber}
                                </strong>
                              </p>
                            </div>
                          </div>
                          {/* <div className="row">
                              <div className="col-12 text-center center-details">
                                <div className="nfssc-name">NFSSC</div>
                                <div className="full-name">
                                  National Fire & Safety Service College
                                </div>
                                <h6 className="center-name">
                                  NAGPUR-MAHARASHTRA-INDIA
                                </h6>
                                <h6 className="reg-no">
                                  Govt.Regd.No : F12412(MH)
                                </h6>
                                <div className="marksheetlogo">
                                  <img src={MarksheerLogo} alt="marksheet" />
                                  <div className="qrlogo">
                                    <img src={QRCode} alt="QR code" />
                                  </div>
                                </div>
                                <div className="marks-details">
                                  <h6>DETAILED MARKS CARD</h6>
                                </div>
                              </div>
                            </div> */}

                          {/* <div className="row">
                            <div className="col-12">
                              <div className="text-center city-title-box">
                                <h6 className="center-name">
                                  NAGPUR-MAHARASHTRA-INDIA
                                </h6>
                                <h6 className="reg-no">
                                  Govt.Regd.No : F12412(MH)
                                </h6>
                              </div>
                            </div>
                          </div> */}

                          <div className="row">
                            <div className="col-12 text-end">
                              <div className="qr-cus">
                                <div
                                  style={{
                                    height: "200px",
                                    margin: "0 auto",
                                    maxWidth: 64,
                                    width: "100%",
                                  }}
                                >
                                  <QRCode
                                    value={QrData}
                                    size={256}
                                    style={{
                                      height: "auto",
                                      maxWidth: "100%",
                                      width: "100%",
                                    }}
                                    fgColor="#000"
                                  />
                                </div>
                                {/* <img
                                  src={QRCode}
                                  alt="QR code"
                                  className="img-fluid"
                                /> */}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="main-caption-boxx">
                                <div className="row Studen_Detail">
                                  <div className="container">
                                    <div className="row">
                                      <div className="col-sm-6 col-md-6">
                                        <table className="table table-borderless cus-studetails-tbl">
                                          <tbody>
                                            <tr>
                                              <th scope="row">NAME</th>
                                              <td>
                                                :{" "}
                                                {`${allMarksheetData?.student?.FirstName} ${allMarksheetData?.student?.LastName}`}
                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row">FATHER'S NAME</th>
                                              <td>
                                                :{" "}
                                                {
                                                  allMarksheetData?.student
                                                    ?.Father
                                                }
                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row">MOTHER'S NAME</th>
                                              <td>
                                                :{" "}
                                                {
                                                  allMarksheetData?.student
                                                    ?.Mother
                                                }
                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row">DATE OF BIRTH</th>
                                              <td>
                                                :{" "}
                                                {allMarksheetData?.student?.Dob}
                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row">PROGRAM</th>
                                              <td>
                                                :{" "}
                                                {allMarksheetData?.course?.Name}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="col-sm-6 col-md-6 ">
                                        <div className="col-sm-6 col-md-6">
                                          <table className="table table-borderless cus-studetails-tbl pl-2">
                                            <tbody>
                                              <tr>
                                                <th scope="row">ROLL NO </th>
                                                <td className="px-3">
                                                  :{" "}
                                                  {
                                                    allMarksheetData?.student
                                                      ?.RollNumber
                                                  }
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="row">SESSION </th>
                                                <td className="px-3">
                                                  :{" "}
                                                  {handlegetSessionDate(
                                                    allMarksheetData?.student
                                                      ?.SessionStart
                                                  )}{" "}
                                                  {"TO"}{" "}
                                                  {handlegetSessionDate(
                                                    allMarksheetData?.student
                                                      ?.SessionEnd
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="row">DURATION</th>

                                                <td className="px-3">
                                                  {allMarksheetData?.course
                                                    ?.CourseDurationMonths ===
                                                  "0"
                                                    ? `${allMarksheetData?.course?.CourseDurationYears} Year`
                                                    : `${allMarksheetData?.course?.CourseDurationMonths} Month`}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      {/* <div className="col-sm-4 col-md-3  personalDetails">
                                    <div className="qrlogo">
                                      <img src={QRCode} alt="QR code" />
                                    </div>
                                  </div> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="container-fluid">
                                    <div className="col-12">
                                      <div className="table-div">
                                        <table className="table table-bordered tablefirst">
                                          <thead>
                                            <tr>
                                              <th
                                                style={{
                                                  verticalAlign: "middle",
                                                }}
                                              >
                                                Subject <br />
                                                Code
                                              </th>
                                              <th
                                                style={{
                                                  verticalAlign: "middle",
                                                }}
                                              >
                                                Subject Title
                                              </th>
                                              <th
                                                style={{
                                                  verticalAlign: "middle",
                                                }}
                                              >
                                                Max
                                                <br /> Marks
                                              </th>
                                              <th
                                                style={{
                                                  verticalAlign: "middle",
                                                }}
                                              >
                                                Obtained <br />
                                                Marks
                                              </th>
                                              <th
                                                style={{
                                                  verticalAlign: "middle",
                                                }}
                                              >
                                                Result
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {allMarksheetData?.subjects?.map(
                                              (value, key) => {
                                                return (
                                                  <>
                                                    <tr>
                                                      <th>
                                                        {`${allMarksheetData?.course?.Name}  ${value?.SubjectCode}`}
                                                      </th>
                                                      <td
                                                        style={{
                                                          textAlign: "left",
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        {value?.SubjectFullName}
                                                      </td>
                                                      <td
                                                        style={{
                                                          textAlign: "left",
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        {value?.SubjectMarks}
                                                      </td>
                                                      <td
                                                        style={{
                                                          textAlign: "left",
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        {value?.Marks}
                                                      </td>
                                                      <td
                                                        style={{
                                                          textAlign: "left",
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        {value?.Result}
                                                      </td>
                                                    </tr>
                                                  </>
                                                );
                                              }
                                            )}

                                            <tr>
                                              <th height="20"></th>
                                              <td height="20"></td>
                                              <td height="20"></td>
                                              <td height="20"></td>
                                              <td height="20"></td>
                                            </tr>
                                            <tr>
                                              <th height="20"></th>
                                              <td height="20"></td>
                                              <td height="20"></td>
                                              <td height="20"></td>
                                              <td height="20"></td>
                                            </tr>
                                            <tr>
                                              <th height="20"></th>
                                              <td height="20"></td>
                                              <td height="20"></td>
                                              <td height="20"></td>
                                              <td height="20"></td>
                                            </tr>
                                            <tr>
                                              <th></th>
                                              <td height="20"></td>
                                              <td
                                                style={{
                                                  textAlign: "left",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {allMarksheetData?.subTotal}
                                              </td>
                                              <td
                                                style={{
                                                  textAlign: "left",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {
                                                  allMarksheetData?.student
                                                    ?.total
                                                }
                                              </td>
                                              <td></td>
                                            </tr>
                                          </tbody>
                                        </table>

                                        <div className="inwords">
                                          Result : Passed And Secured Marks (in
                                          Words) :{" "}
                                          <span>
                                            {numberToWords.toWords(
                                              allMarksheetData?.student
                                                ?.total == undefined
                                                ? 0
                                                : allMarksheetData?.student
                                                    ?.total
                                            )}
                                          </span>
                                        </div>

                                        <table className="table table-bordered tablesecond w-50">
                                          <thead>
                                            <tr>
                                              <th>Duration</th>
                                              <th>Obtained Marks</th>
                                              <th>Total Marks</th>
                                              <th>Remark</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <th>
                                                {allMarksheetData?.course
                                                  ?.CourseDurationMonths === "0"
                                                  ? `${allMarksheetData?.course?.CourseDurationYears} Year`
                                                  : `${allMarksheetData?.course?.CourseDurationMonths} Month`}
                                              </th>
                                              <td className="fw-bold">
                                                {" "}
                                                {
                                                  allMarksheetData?.student
                                                    ?.total
                                                }
                                              </td>
                                              <td className="fw-bold">
                                                {allMarksheetData?.subTotal}
                                              </td>
                                              <td className="fw-bold">
                                                {allMarksheetData?.resultStatus}
                                              </td>
                                            </tr>
                                            <tr>
                                              <th height="20"></th>
                                              <td
                                                className="fw-bold"
                                                height="20"
                                              ></td>
                                              <td
                                                className="fw-bold"
                                                height="20"
                                              ></td>
                                              <td
                                                className="fw-bold"
                                                height="20"
                                              ></td>
                                            </tr>
                                            <tr>
                                              <th height="20"></th>
                                              <td
                                                className="fw-bold"
                                                height="20"
                                              ></td>
                                              <td
                                                className="fw-bold"
                                                height="20"
                                              ></td>
                                              <td
                                                className="fw-bold"
                                                height="20"
                                              ></td>
                                            </tr>
                                            <tr>
                                              <th>Grand Total</th>
                                              <td className="fw-bold">
                                                {
                                                  allMarksheetData?.student
                                                    ?.total
                                                }
                                              </td>
                                              <td className="fw-bold">
                                                {" "}
                                                {allMarksheetData?.subTotal}
                                              </td>
                                              <td className="fw-bold">
                                                {(+allMarksheetData?.student
                                                  ?.percentage).toFixed()}
                                                %
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* bottom */}
                          <div className="row">
                            <div className="col-12">
                              <div className="fotter-box">
                                <div className="row">
                                  <div className="col-6 ">
                                    <div className="issueData text-start">
                                      <span className="center-state">
                                        {" "}
                                        {allMarksheetData?.center?.Dist} (
                                        {allMarksheetData?.center?.State})
                                      </span>
                                      <div>
                                        Date of Issue :{" "}
                                        {new Date(
                                          allMarksheetData?.DateOfIssue
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "long",
                                          day: "numeric",
                                        })}{" "}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 ">
                                    <div className="issueData text-center">
                                      <div>REGISTRAR</div>
                                      <img
                                        src={barCode}
                                        className="img-fluid"
                                        alt="barcode"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      {/*Add Roll No modal form */}
      <div
        className="modal fade"
        id="editcourse"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handleUpdateMarksData(e)}>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Roll Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={marksheetUpdateData.RollNumber}
                    onChange={(e) =>
                      handleUpdateOnChange("RollNumber", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Enrollment Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={marksheetUpdateData.EnrollmentNumber}
                    onChange={(e) =>
                      handleUpdateOnChange("EnrollmentNumber", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    DMC Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={marksheetUpdateData.DmcNumber}
                    onChange={(e) =>
                      handleUpdateOnChange("DmcNumber", e.target.value)
                    }
                  />
                </div>

                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Date Of Issue
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={marksheetUpdateData.DateOfIssue}
                    onChange={(e) =>
                      handleUpdateOnChange("DateOfIssue", e.target.value)
                    }
                  />
                </div>
                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-thumbs-up mx-2"></i>Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* certificate modal  */}
      <div
        className="modal fade"
        id="certificateModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="row justify-content-end">
              <div className="col-2 text-md-right mb-3 mb-md-0">
                <button
                  className="hide-on-print form-button-styles"
                  onClick={handleDownloadCertificate}
                >
                  <i className="fa-solid fa-file-pdf mx-2"></i>
                  PDF
                </button>
              </div>
              <div className="col-2 text-md-right">
                <button
                  className="hide-on-print form-button-styles"
                  onClick={handleCertificatePrint}
                >
                  <i className="fa-solid fa-print mx-2"></i>
                  Print
                </button>
              </div>
            </div>
            <div className="modal-body" id="cer-pdf-content">
              <section>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <form>
                        <div className="marksheet-box">
                          <div className="row">
                            <div className="col-sm-6 enrolmentNo txt-cntr">
                              <p className="mb-0 text-start">
                                <strong>
                                  Sr No :{" "}
                                  <span className="text-decoration-underline">
                                    {
                                      allMarksheetData?.student
                                        ?.EnrollmentNumber
                                    }
                                  </span>
                                </strong>
                              </p>
                            </div>

                            <div className="col-sm-6 txt-cntr">
                              <p className="mb-0 text-end">
                                <strong>
                                  Govt.Regd.No. :{" "}
                                  <span className="text-decoration-underline">
                                    {allMarksheetData?.student?.DmcNumber}
                                  </span>
                                </strong>
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            {/* <div className="col-12">
                              <div className="text-center city-title-box">
                                <h6 className="center-name">
                                  NAGPUR-MAHARASHTRA-INDIA
                                </h6>
                                <h6 className="reg-no">SECTOR SKILL COUNCIL</h6>
                                <h6 className="reg-no">
                                  Incorporated under the legislation of Mistry
                                  of Corporate Affairs
                                </h6>
                                <h6 className="reg-no">
                                  {" "}
                                  29 & 30(1) Constitution of India,
                                  Section(8).Act-2013 of
                                </h6>
                                <h6 className="reg-no">Goverment of India</h6>
                              </div>
                            </div> */}
                          </div>

                          <div className="row">
                            <div className="col-12 text-end">
                              <div className="cirtificate-cus">
                                <img
                                  src={`https://backend.nileshdawande.in/images/${allMarksheetData?.student?.Picture}`}
                                  alt="QR code"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="main-caption-box">
                                <div className="row Studen_Detail">
                                  <div className="container">
                                    <div className="row">
                                      <div className="col-sm-6 col-md-6">
                                        <table className="table table-borderless cus-studetails-tbl">
                                          <tbody>
                                            <tr>
                                              <th scope="row">NAME</th>
                                              <td>
                                                :{" "}
                                                {`${allMarksheetData?.student?.FirstName} ${allMarksheetData?.student?.LastName}`}
                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row">FATHER'S NAME</th>
                                              <td>
                                                :{" "}
                                                {
                                                  allMarksheetData?.student
                                                    ?.Father
                                                }
                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row">MOTHER'S NAME</th>
                                              <td>
                                                :{" "}
                                                {
                                                  allMarksheetData?.student
                                                    ?.Mother
                                                }
                                              </td>
                                            </tr>
                                            {/* <tr>
                                              <th scope="row">DATE OF BIRTH</th>
                                              <td>
                                                :{" "}
                                                {allMarksheetData?.student?.Dob}
                                              </td>
                                            </tr>
                                            <tr>
                                              <th scope="row">PROGRAM</th>
                                              <td>
                                                :{" "}
                                                {allMarksheetData?.course?.Name}
                                              </td>
                                            </tr> */}
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="col-sm-6 col-md-6 ">
                                        <div className="col-sm-6 col-md-6">
                                          <table className="table table-borderless cus-studetails-tbl">
                                            <tbody>
                                              <tr>
                                                <th scope="row">ROLL NO </th>
                                                <td className="px-3">
                                                  :{" "}
                                                  {
                                                    allMarksheetData?.student
                                                      ?.RollNumber
                                                  }
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="row">SESSION </th>
                                                <td className="px-3">
                                                  :{" "}
                                                  {handlegetSessionDate(
                                                    allMarksheetData?.student
                                                      ?.SessionStart
                                                  )}{" "}
                                                  {"TO"}{" "}
                                                  {handlegetSessionDate(
                                                    allMarksheetData?.student
                                                      ?.SessionEnd
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="row">DURATION</th>

                                                <td className="px-3">
                                                  {" "}
                                                  :{" "}
                                                  {allMarksheetData?.course
                                                    ?.CourseDurationMonths ===
                                                  "0"
                                                    ? `${allMarksheetData?.course?.CourseDurationYears} Year`
                                                    : `${allMarksheetData?.course?.CourseDurationMonths} Month`}{" "}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="row">EXAMINATION</th>

                                                <td className="px-3">
                                                  {" "}
                                                  :{" "}
                                                  {handlegetSessionDate(
                                                    allMarksheetData?.student
                                                      ?.SessionEnd
                                                  )}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="container-fluid">
                                    <div className="col-12">
                                      <div className="cirtificate-container">
                                        <h6>This is to certify that</h6>
                                        <h5>MANOJ KUTHE S/O DOLIRAM KUTHE</h5>
                                        <h6>
                                          having demonstrated knowledge and
                                          proficiency
                                        </h6>
                                        <h6>
                                          satisfactory to the Examining
                                          Committee
                                        </h6>
                                        <h6>
                                          successfully completed the programme
                                          of{" "}
                                        </h6>
                                        <h5>
                                          PG DIPLOMA IN FIRE-SAFETY AND HAZARD
                                          MANAGEMENT{" "}
                                        </h5>
                                        <h6>
                                          with{" "}
                                          <sapn className="text-decoration-underline">
                                            A Grade
                                          </sapn>
                                        </h6>
                                        <h6>
                                          sealed with the Common seal of the
                                          Academy
                                        </h6>
                                        <h6>
                                          {" "}
                                          on{" "}
                                          <sapn className="text-decoration-underline">
                                            10-Aug-2023
                                          </sapn>{" "}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* bottom */}
                          <div className="row">
                            <div className="col-12">
                              <div className="fotter-box">
                                <div className="row">
                                  <div className="col-4 ">
                                    <div className="crt-footer">
                                      COURSE CO-ORDINATOR
                                    </div>
                                  </div>
                                  <div className="col-4 ">
                                    <div className="crt-footer">PRESIDENT</div>
                                  </div>
                                  <div className="col-4 ">
                                    <div className="crt-footer">REGISTRAR</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      {/* edit modal */}

      <div
        className="modal fade"
        id="editModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Student Data - Step {step}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                {renderStepContent()}
                <div className="d-flex justify-content-between mt-3">
                  {step !== 1 && (
                    <button
                      type="button"
                      className="button-styles"
                      onClick={handlePrevStep}
                    >
                      Previous
                    </button>
                  )}
                  {step !== 2 && (
                    <button
                      type="button"
                      className="button-styles"
                      onClick={handleNextStep}
                    >
                      Next
                    </button>
                  )}
                  {step === 2 && (
                    <button type="submit" className="button-styles">
                      <i class="fa-solid fa-thumbs-up mx-2"></i>
                      Save
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer position="top-right" />
    </Layout>
  );
};
export default InvoicePageSection;
