import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
const Header = ({ togglesideBar, toggle }) => {
  const navigate = useNavigate();
  const handleDelete = () => {
    Swal.fire({
      text: "Are you sure you won't be Log out?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Log out",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      localStorage.clear();
      navigate("/");
    });
  };
  return (
    <div className="header-box">
      <div className="toggle-btn" onClick={togglesideBar}>
        <i className="bi bi-list"></i>
      </div>
      <div>
        <strong className="text-light">Admin Panel </strong>
      </div>

      <div className="dropdown">
        <Link
          to={""}
          className="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://github.com/mdo.png"
            alt="error"
            width={32}
            height={32}
            className="rounded-circle me-2"
          />
        </Link>
        <ul className="dropdown-menu text-small shadow">
          {/* <li>
            <Link to={""} className="dropdown-item" href="#">
              New project...
            </Link>
          </li>
          <li>
            <Link to={""} className="dropdown-item" href="#">
              Settings
            </Link>
          </li>
          <li>
            <Link to={""} className="dropdown-item" href="#">
              Profile
            </Link>
          </li> */}
          <li>
            <button className="dropdown-item" onClick={() => handleDelete()}>
              Sign out
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
