import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { FiSearch } from "react-icons/fi";
import Swal from "sweetalert2";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles.css";
const PaymentPageSection = () => {
  const [getAllpaymentData, setGetAllpaymentData] = useState([]);
  const [image1file, setImage1file] = useState("");
  const [image1fileUrl, setImage1fileUrl] = useState("");

  const [image2file, setImage2file] = useState("");
  const [image2fileUrl, setImage2fileUrl] = useState("");

  const [image3file, setImage3file] = useState("");
  const [image3fileUrl, setImage3fileUrl] = useState("");

  const [image4file, setImage4file] = useState("");
  const [image4fileUrl, setImage4fileUrl] = useState("");

  const [loading, setLoading] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [paymentData, setpaymentData] = useState({
    AccountName: "",
    BankName: "",
    IfscCode: "",
    AccountNumber: "",
    PaymentPhoto: "",
  });

  const [paymentUpdateData, setpaymentUpdateData] = useState({
    AccountName: "",
    BankName: "",
    IfscCode: "",
    AccountNumber: "",
    PaymentPhoto: "",
  });

  const handleOnChange = (key, value) => {
    setpaymentData((paymentData) => ({
      ...paymentData,
      [key]: value,
    }));
  };

  const handleUpdateOnChange = (key, value) => {
    setpaymentUpdateData((paymentUpdateData) => ({
      ...paymentUpdateData,
      [key]: value,
    }));
  };

  const handleDelete = (id) => {
    Swal.fire({
      text: "Are you sure you won't be deleted?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const setDeletepaymentData = await axios.delete(
            `api/admin/delete-payment/${id}`
          );
          if (setDeletepaymentData.data.isPaymentDeleted === true) {
            toast.success(setDeletepaymentData.data.msg);
            handleGetpaymentData();
          }
        } catch (error) {
          if (error.response.status === 400) {
            const message_400 = error?.response?.data?.message;
            toast.error(message_400);
          } else if (error.response.status === 401) {
            const message_401 = error?.response?.data?.message;
            toast.error(message_401);
          } else if (error.response.status === 404) {
            const message_404 = error?.response?.data?.message;
            console.log("message_404", message_404);
            toast.error(message_404);
          } else if (error.response.status === 500) {
            const message_500 = error?.response?.data?.message;
            toast.error(message_500);
          } else {
            toast.error("something went wrong");
          }
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("Cancelled");
      }
    });
  };
  const handleGetpaymentData = async () => {
    try {
      const getNews = await axios.get("api/admin/get-payment");
      if (getNews?.data?.isPaymentFound === true) {
        setGetAllpaymentData(getNews?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };
  const handlePostpaymentData = async (e) => {
    e.preventDefault();
    try {
      const setpaymentData = await axios.post(
        "api/admin/add-payment",
        paymentData
      );
      if (setpaymentData?.data?.isPaymentCreated === true) {
        toast.success(setpaymentData?.data?.msg);
        window.location.reload();
        handleGetpaymentData();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
    console.log("news Data", paymentData);
  };

  const handleUpdatepaymentData = async (e) => {
    e.preventDefault();
    try {
      const setUpdatepaymentData = await axios.put(
        `api/admin/update-payment/${updateId}`,
        paymentUpdateData
      );
      if (setUpdatepaymentData.data.isPaymentUpdated === true) {
        toast.success(setUpdatepaymentData.data.msg);
        handleGetpaymentData();
        window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };
  const handleSetSatus = async (id, mystatus) => {
    try {
      const setStatuspaymentData = await axios.put(
        `api/admin/update-status-payment/${id}`,
        {
          status: !mystatus,
        }
      );
      if (setStatuspaymentData.data.isPaymentUpdated === true) {
        toast.success(setStatuspaymentData.data.msg);
        handleGetpaymentData();
        window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  //   handle image function

  const handleImage1 = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];
    if (newFile !== undefined) {
      reader.onloadend = async () => {
        setLoading(true);
        try {
          if (newFile.size > 1024 * 1024) {
            alert("Image size should be less then 1 Mb");
          } else {
            const formdata = new FormData();
            formdata.append("Images", newFile);
            const resp = await axios.post(`api/upload/images`, formdata);
            if (resp.status === 200) {
              const uploadedImg = resp.data.image;
              setImage1file(uploadedImg);
              setImage1fileUrl(reader.result);
              handleOnChange("PaymentPhoto", uploadedImg);
              handleUpdateOnChange("PaymentPhoto", uploadedImg);
              toast.success(resp?.data?.message);
            } else {
              toast.error("Error");
            }
          }
        } catch (error) {
          if (error.response.status === 400) {
            var err_400 = error?.response?.data?.message;
            alert(err_400);
          } else if (error.response.status === 401) {
            const err_401 = error?.response?.data?.message;
            alert(err_401);
          } else if (error.response.status === 500) {
            const err_500 = error?.response?.data?.message;
            alert(err_500);
          } else {
            alert("error in News one image");
            console.log("error in News one image", error);
          }
        }
        setLoading(false);
      };
      reader.readAsDataURL(newFile);
    } else {
      setImage1fileUrl(null);
    }
  };

  let $handleImage1 = null;
  if (image1fileUrl) {
    $handleImage1 = (
      <img src={image1fileUrl} alt="Preview" className="news-img" />
    );
  } else {
    $handleImage1 = (
      <div className="previewText">Please select Image for Preview</div>
    );
  }

  // ----
  console.log("updateId", updateId);
  useEffect(() => {
    handleGetpaymentData();
  }, []);
  return (
    <Layout>
      <form className="d-flex mb-3 my-3">
        <div className="row w-100 justify-content-between g-3">
          <div className="col-lg-5 col-md-6 col-sm-12">
            <div className="input-group ">
              <input
                type="text"
                className="form-control"
                placeholder="Search Address"
                aria-label="Search"
                aria-describedby="button-addon2"
              />
              <button
                className="button-styles"
                type="button"
                id="button-addon2"
              >
                <FiSearch />
              </button>
            </div>
          </div>
        </div>
      </form>
      <button
        type="button"
        className="button-styles my-3"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        data-bs-whatever="@mdo"
      >
        <i class="fa-solid fa-plus mx-2"></i>
        Add Payment
      </button>
      <div className="table-responsive">
        <div>
          <div className="table-responsive table-box">
            <table className="table table-bordered">
              <thead>
                <tr className="table-head">
                  <th>Payment Id</th>
                  <th>AccountName</th>
                  <th>BankName</th>
                  <th>IfscCode</th>
                  <th>AccountNumber</th>
                  <th>Image</th>
                  <th>IsActive</th>
                  <th>createdAt</th>
                  <th>Status</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {getAllpaymentData.map((nft, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{nft.AccountName}</td>
                    <td>{nft.BankName}</td>
                    <td>{nft.IfscCode}</td>
                    <td>{nft.AccountNumber}</td>
                    <td>
                      <div className="tbl-img">
                        <img
                          src={`https://backend.nileshdawande.in/images/${nft?.PaymentPhoto}`}
                          alt="img1"
                        />
                      </div>
                    </td>
                    <td>{nft.IsActive === true ? "Active" : "InActive"}</td>

                    <td>{nft.createdAt}</td>
                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        onClick={() => handleSetSatus(nft._id, nft.IsActive)}
                      >
                        <i class="fa-solid fa-trash mx-2"></i>
                        {nft.IsActive === true ? "InActive" : "Active"}
                      </button>
                    </td>

                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#editcourse"
                        onClick={() => (
                          setpaymentUpdateData({
                            AccountName: nft.AccountName,
                            BankName: nft.BankName,
                            IfscCode: nft.IfscCode,
                            AccountNumber: nft.AccountNumber,
                            PaymentPhoto: nft.PaymentPhoto,
                          }),
                          setUpdateId(nft._id)
                        )}
                      >
                        <i class="fa-solid fa-pen-to-square mx-2"></i>
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        onClick={() => handleDelete(nft._id)}
                      >
                        <i class="fa-solid fa-trash mx-2"></i>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/*Add modal form */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add News
              </h5>
              <button
                type="button"
                className="btn-close cus-btn-close "
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handlePostpaymentData(e)}>
                <div className="mb-2">
                  <label className="col-form-label">Account Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={paymentData.AccountName}
                    onChange={(e) =>
                      handleOnChange("AccountName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label className="col-form-label">Bank Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={paymentData.BankName}
                    onChange={(e) => handleOnChange("BankName", e.target.value)}
                  />
                </div>
                <div className="mb-2">
                  <label className="col-form-label">IFSC Code</label>
                  <input
                    type="text"
                    className="form-control"
                    value={paymentData.IfscCode}
                    onChange={(e) => handleOnChange("IfscCode", e.target.value)}
                  />
                </div>
                <div className="mb-2">
                  <label className="col-form-label">Account Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={paymentData.AccountNumber}
                    onChange={(e) =>
                      handleOnChange("AccountNumber", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Image 1
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/jpg, image/jpeg, image/png"
                        onChange={(e) => handleImage1(e)}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-2">{$handleImage1}</div>
                    </div>
                  </div>
                </div>
                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-thumbs-up mx-2"></i>Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/*Edit  modal form */}
      <div
        className="modal fade"
        id="editcourse"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Payment
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handleUpdatepaymentData(e)}>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Account Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={paymentUpdateData.AccountName}
                    onChange={(e) =>
                      handleUpdateOnChange("AccountName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label className="col-form-label">Bank Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={paymentUpdateData.BankName}
                    onChange={(e) =>
                      handleUpdateOnChange("BankName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label className="col-form-label">IFSC Code</label>
                  <input
                    type="text"
                    className="form-control"
                    value={paymentUpdateData.IfscCode}
                    onChange={(e) =>
                      handleUpdateOnChange("IfscCode", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label className="col-form-label">Account Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={paymentUpdateData.AccountNumber}
                    onChange={(e) =>
                      handleUpdateOnChange("AccountNumber", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Image 1
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/jpg, image/jpeg, image/png"
                        onChange={(e) => handleImage1(e)}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-2">{$handleImage1}</div>
                    </div>
                  </div>
                </div>

                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-thumbs-up mx-2"></i>Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer position="top-right" />
    </Layout>
  );
};
export default PaymentPageSection;
