import React, { useState } from "react";
import Sidebar from "./sidebar";
import Header from "./header";

import { Helmet } from "react-helmet";

const Layout = (props) => {
  const { children, title, description, keywords, author } = props;
  const [toggle, settoggle] = useState(false);
  const togglesideBar = () => {
    settoggle(!toggle);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content={author} />
        <title>{title}</title>
      </Helmet>
      <div className="main">
        <Sidebar toggle={toggle} togglesideBar={togglesideBar} />
        <div className={`content ${toggle && "show"}`}>
          <div className="container">
            <Header toggle={toggle} togglesideBar={togglesideBar} />
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
