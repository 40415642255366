import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { FiSearch } from "react-icons/fi";
import Swal from "sweetalert2";
import { states } from "../../../data";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paginate from "../../Sections/Paginate/index";
import logo from "../../../assets/images/finalLogo.jpeg";
import "./styles.css";
const SubAdminPageSection = () => {
  const [getAllDistrict, setAllDistrict] = useState([]);
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [centerName, setCenterName] = useState("");
  const [centerHoName, setCenterHoName] = useState("");
  const [centerHoMobile, setCenterHoMobile] = useState("");
  const [centerEmail, setCenterEmail] = useState("");
  const [centerPass, setCenterPass] = useState("");
  const [loading, setLoading] = useState(false);
  const [image1file, setImage1file] = useState("");
  const [image1fileUrl, setImage1fileUrl] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [getSubAdminData, setGetSubAdminData] = useState([]);
  const [getSudentData, setGetSudentData] = useState([]);
  const [resetKey, setResetKey] = useState("");
  const [studentId, setStudentId] = useState("");
  const [hallTicketData, setHallTicketData] = useState([]);
  const [newCenter, setNewCenter] = useState("");
  const [addRollData, setAddRollData] = useState({
    DmcNumber: "",
    RollNumber: "",
    EnrollmentNumber: "",
  });
  const [updateData, setUpdateData] = useState({
    centerNumber: "",
    centerName: "",
    centerState: "",
    centerDist: "",
    centerHoName: "",
    centerHoMobile: "",
    centerEmail: "",
    centerImage: "",
  });

  // paginate
  const [pageCount, setPageCount] = useState(0);
  const [pagenumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(5);
  const [isEmpty, setIsEmpty] = useState(true);

  const handleUpdateOnChange = (key, value) => {
    setUpdateData((updateData) => ({
      ...updateData,
      [key]: value,
    }));
  };

  const handleRollOnChange = (key, value) => {
    setAddRollData((addRollData) => ({
      ...addRollData,
      [key]: value,
    }));
  };

  const handleGetState = (state) => {
    setState(state);
    handleUpdateOnChange("centerState", state);
    const getdistrict = states.filter((value) => {
      return value.state === state;
    });
    setAllDistrict(getdistrict[0].districts);
  };

  const handlegetData = async (id) => {
    try {
      const getrollData = await axios.get(`api/admin/get-other-details-rollnumber/${id}`);
      if (getrollData?.status === 200) {
        console.log("getrollData",getrollData);
        setIsEmpty(false);
        setAddRollData({
          DmcNumber: getrollData?.data?.data?.DmcNumber,
          RollNumber: getrollData?.data?.data?.RollNumber,
          EnrollmentNumber: getrollData?.data?.data?.EnrollmentNumber,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        setIsEmpty(true);
        setAddRollData({
          DmcNumber: "",
          RollNumber: "",
          EnrollmentNumber: "",
        });
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };
  const handleDelete = (id) => {
    Swal.fire({
      // title: "Are you sure?",
      text: "Are you sure you won't be deleted?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const setDeletesubAdminData = await axios.delete(
            `api/admin/center/delete-child-admin/${id}`
          );
          if (setDeletesubAdminData?.status === 200) {
            toast.success(setDeletesubAdminData.data.msg);
            handleGetsubAminData();
          }
        } catch (error) {
          if (error.response.status === 400) {
            const message_400 = error?.response?.data?.message;
            toast.error(message_400);
          } else if (error.response.status === 401) {
            const message_401 = error?.response?.data?.message;
            toast.error(message_401);
          } else if (error.response.status === 404) {
            const message_404 = error?.response?.data?.message;
            console.log("message_404", message_404);
            toast.error(message_404);
          } else if (error.response.status === 500) {
            const message_500 = error?.response?.data?.message;
            toast.error(message_500);
          } else {
            toast.error("something went wrong");
          }
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("Cancelled");
      }
    });
  };
  const handlePostSubAdmin = async (e) => {
    e.preventDefault();
    const ajax = {
      centerImage: image1file,
      centerName,
      centerState: state,
      centerDist: district,
      centerHoName,
      centerHoMobile,
      centerEmail,
      centerPass,
    };
    try {
      const setSubAdminData = await axios.post(
        "api/admin/center/create-admin",
        ajax
      );
      console.log("setSubAdminData", setSubAdminData);
      if (setSubAdminData?.data?.isCenterRegister === true) {
        toast.success(setSubAdminData?.data?.msg);
        window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  //   handle image function
  const handleImage1 = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];
    if (newFile !== undefined) {
      reader.onloadend = async () => {
        setLoading(true);
        try {
          if (newFile.size > 1024 * 1024) {
            alert("Image size should be less then 1 Mb");
          } else {
            const formdata = new FormData();
            formdata.append("Images", newFile);
            const resp = await axios.post(`api/upload/images`, formdata);
            if (resp.status === 200) {
              const uploadedImg = resp.data.image;
              setImage1file(uploadedImg);
              setImage1fileUrl(reader.result);
              handleUpdateOnChange("centerImage", uploadedImg);
              toast.success(resp?.data?.message);
            } else {
              toast.error("Error");
            }
          }
        } catch (error) {
          if (error.response.status === 400) {
            var err_400 = error?.response?.data?.message;
            alert(err_400);
          } else if (error.response.status === 401) {
            const err_401 = error?.response?.data?.message;
            alert(err_401);
          } else if (error.response.status === 500) {
            const err_500 = error?.response?.data?.message;
            alert(err_500);
          } else {
            alert("error in News one image");
            console.log("error in News one image", error);
          }
        }
        setLoading(false);
      };
      reader.readAsDataURL(newFile);
    } else {
      setImage1fileUrl(null);
    }
  };

  console.log("isEmpty", isEmpty);

  let $handleImage1 = null;
  if (image1fileUrl) {
    $handleImage1 = (
      <img src={image1fileUrl} alt="Preview" className="news-img" />
    );
  } else {
    $handleImage1 = (
      <div className="previewText">Please select Image for Preview</div>
    );
  }
  //   End handle image function

  const handleGetsubAminData = async () => {
    try {
      const getSubAdmin = await axios.get("api/admin/center/get-child-admin");
      if (getSubAdmin?.status === 200) {
        setGetSubAdminData(getSubAdmin?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleUpdateSubAdminData = async (e) => {
    e.preventDefault();
    try {
      const setUpdatesubadminData = await axios.put(
        `api/admin/center/update-child-admin/${updateId}`,
        updateData
      );
      console.log("setUpdatesubadminData", setUpdatesubadminData);
      if (setUpdatesubadminData?.status === 200) {
        toast.success(setUpdatesubadminData.data.msg);
        handleGetsubAminData();
        window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!resetKey == "") {
      try {
        const setresetData = await axios.put(
          `api/admin/center/update-child-token/${updateId}`,
          {
            newToken: resetKey,
          }
        );
        console.log("setresetData", setresetData);
        if (setresetData?.status === 200) {
          toast.success(setresetData.data.msg);
          handleGetsubAminData();
          window.location.reload();
        }
      } catch (error) {
        if (error.response.status === 400) {
          const message_400 = error?.response?.data?.message;
          toast.error(message_400);
        } else if (error.response.status === 401) {
          const message_401 = error?.response?.data?.message;
          toast.error(message_401);
        } else if (error.response.status === 404) {
          const message_404 = error?.response?.data?.message;
          console.log("message_404", message_404);
          toast.error(message_404);
        } else if (error.response.status === 500) {
          const message_500 = error?.response?.data?.message;
          toast.error(message_500);
        } else {
          toast.error("something went wrong");
        }
      }
    } else {
      toast.error("please enter password ");
    }
  };

  const handleGetsubjectData = async (courseId, pageN, pageL) => {
    try {
      const getSubData = await axios.get(
        `api/admin/all-students-center/${pageN}/${pageL}/${courseId}`
      );
      if (getSubData?.data?.isStudentFound === true) {
        setGetSudentData(getSubData?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        setGetSudentData([]);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handlePageClick = async (event) => {
    setPageNumber(event.selected + 1);
    handleGetsubjectData(updateId, event.selected + 1, pageLimit);
  };

  const handleCountPagination = async (courseId) => {
    try {
      const getCountNo = await axios.get(
        `api/admin/all-students-center/count/${courseId}`
      );

      if (getCountNo?.status === 200) {
        let x = getCountNo?.data?.count / pageLimit;
        if (Number.isInteger(x)) {
          setPageCount(x);
        } else {
          x = x + 1;
          x = parseInt(x);
          setPageCount(x);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRollNumberData = async (e) => {
    e.preventDefault();
    try {
      const setRollNoData = await axios.post(
        `api/admin/create-other-details/${studentId}`,
        addRollData
      );
      console.log("setRollNoData", setRollNoData);
      if (setRollNoData?.status === 200) {
        toast.success(setRollNoData?.data?.msg);
        window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };
  const handleRollNumberDataUpdate = async (e) => {
    debugger;
    e.preventDefault();
    try {
      const setUpdateRollNoData = await axios.put(
        `api/admin/update-other-details/${studentId}`,
        addRollData
      );
      console.log("setUpdatesubadminData", setUpdateRollNoData);
      if (setUpdateRollNoData?.status === 200) {
        toast.success(setUpdateRollNoData.data.msg);
        handleGetsubAminData();
        window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleEditCenter = async (e) => {
    e.preventDefault();
    if (!newCenter == "") {
      try {
        const setCenter = await axios.put(
          `api/admin/edit-student-center/${updateId}/${studentId}`,
          { newCenter: newCenter }
        );

        if (setCenter?.status === 200) {
          toast.success(setCenter?.data?.msg);
          window.location.reload();
        }
      } catch (error) {
        if (error.response.status === 400) {
          const message_400 = error?.response?.data?.message;
          toast.error(message_400);
        } else if (error.response.status === 401) {
          const message_401 = error?.response?.data?.message;
          toast.error(message_401);
        } else if (error.response.status === 404) {
          const message_404 = error?.response?.data?.message;
          console.log("message_404", message_404);
          toast.error(message_404);
        } else if (error.response.status === 500) {
          const message_500 = error?.response?.data?.message;
          toast.error(message_500);
        } else {
          toast.error("something went wrong");
        }
      }
    } else {
      toast.error("Please Select Center");
    }
  };

  const getHallTicketData = async (sId) => {
    debugger;
    try {
      const getSubData = await axios.get(`api/admin/get-other-details/${sId}`);
      if (getSubData?.status === 200) {
        setHallTicketData(getSubData?.data?.data);
        setIsEmpty(true);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        setIsEmpty(false);
        console.log("message_404", message_404);
        setGetSudentData([]);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };
  // useEffect(() => {
  //   const isEmpty = Object.values(addRollData).every((value) => value === "");
  //   if (isEmpty) {
  //     setIsEmpty(true);
  //   } else {
  //     setIsEmpty(false);
  //     // console.log("addRollData is not empty");
  //   }
  // }, [addRollData]);
  useEffect(() => {
    handleGetsubAminData();
  }, []);
  return (
    <Layout>
      <form className="d-flex mb-3 my-3">
        <div className="row w-100 justify-content-between g-3">
          <div className="col-lg-5 col-md-6 col-sm-12">
            <div className="input-group ">
              <input
                type="text"
                className="form-control"
                placeholder="Search Address"
                aria-label="Search"
                aria-describedby="button-addon2"
              />
              <button
                className="button-styles"
                type="button"
                id="button-addon2"
              >
                <FiSearch />
              </button>
            </div>
          </div>
        </div>
      </form>
      <button
        type="button"
        className="button-styles my-3"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        <i class="fa-solid fa-plus mx-2"></i>
        Create SubAdmin
      </button>
      <div className="table-responsive">
        <div>
          <div className="table-responsive table-box">
            <table className="table table-bordered">
              <thead>
                <tr className="table-head">
                  <th>center Id</th>
                  <th>centerHoName</th>
                  <th>centerEmail</th>
                  <th>centerName</th>
                  <th>centerNumber</th>
                  <th>Rest-Password</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {getSubAdminData.map((nft, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{nft?.centerHoName}</td>
                    <td>{nft?.centerEmail}</td>
                    <td>{nft?.centerName}</td>
                    <td>{nft?.centerNumber}</td>
                    <td>
                      {" "}
                      <button
                        className="button-styles"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#getstudentmodal"
                        onClick={() => (
                          handleCountPagination(nft._id),
                          handleGetsubjectData(nft._id, 1, pageLimit),
                          setUpdateId(nft._id)
                        )}
                      >
                        <i className="fa-solid fa-key mx-2"></i>
                        Get Student
                      </button>
                    </td>
                    <td>
                      {" "}
                      <button
                        className="button-styles"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#resetkeymodal"
                        onClick={() => setUpdateId(nft._id)}
                      >
                        <i className="fa-solid fa-key mx-2"></i>
                        Rest-Password
                      </button>
                    </td>
                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#editsubadmin"
                        onClick={() => (
                          setUpdateData({
                            centerNumber: nft?.centerNumber,
                            centerName: nft?.centerName,
                            centerState: nft?.centerState,
                            centerDist: nft?.centerDist,
                            centerHoName: nft?.centerHoName,
                            centerHoMobile: nft?.centerHoMobile,
                            centerEmail: nft?.centerEmail,
                            centerImage: nft?.centerImage,
                          }),
                          setUpdateId(nft._id)
                        )}
                      >
                        <i class="fa-solid fa-pen-to-square mx-2"></i>
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        onClick={() => handleDelete(nft._id)}
                      >
                        <i class="fa-solid fa-trash mx-2"></i>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Add modal form */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Create Sub Admin
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handlePostSubAdmin(e)}>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Center Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={centerName}
                    onChange={(e) => setCenterName(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    State
                  </label>
                  <select
                    className="form-control"
                    onChange={(e) => handleGetState(e.target.value)}
                  >
                    <option value="" defaultChecked>
                      ---Select State---
                    </option>
                    {states?.map((value, key) => {
                      return (
                        <option value={value?.state}>{value?.state}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    District
                  </label>
                  <select
                    className="form-control"
                    onChange={(e) => setDistrict(e.target.value)}
                  >
                    <option value="" defaultChecked>
                      ---Select District---
                    </option>
                    {getAllDistrict?.map((value, key) => {
                      return <option value={value}>{value}</option>;
                    })}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Center HostName
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={centerHoName}
                    onChange={(e) => setCenterHoName(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Center Mobile No
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={centerHoMobile}
                    onChange={(e) => setCenterHoMobile(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Center Image
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/jpg, image/jpeg, image/png"
                        onChange={(e) => handleImage1(e)}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-2">{$handleImage1}</div>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Center Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    value={centerEmail}
                    onChange={(e) => setCenterEmail(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Center Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    value={centerPass}
                    onChange={(e) => setCenterPass(e.target.value)}
                  />
                </div>
                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-thumbs-up mx-2"></i>Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Edit modal form */}
      <div
        className="modal fade"
        id="editsubadmin"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Sub Admin
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handleUpdateSubAdminData(e)}>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Center Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={updateData.centerName}
                    onChange={(e) =>
                      handleUpdateOnChange("centerName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    State
                  </label>
                  <select
                    className="form-control"
                    value={updateData?.centerState}
                    onChange={(e) => handleGetState(e.target.value)}
                  >
                    <option value="" defaultChecked>
                      ---Select State---
                    </option>
                    {states?.map((value, key) => {
                      return (
                        <option value={value?.state}>{value?.state}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    District
                  </label>
                  <select
                    className="form-control"
                    value={updateData?.centerDist}
                    onChange={(e) =>
                      handleUpdateOnChange("centerDist", e.target.value)
                    }
                  >
                    <option value="">---Select District---</option>
                    {getAllDistrict?.map((value, key) => {
                      return <option value={value}>{value}</option>;
                    })}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Center HostName
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={updateData?.centerHoName}
                    onChange={(e) =>
                      handleUpdateOnChange("centerHoName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Center Mobile No
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={updateData?.centerHoMobile}
                    onChange={(e) =>
                      handleUpdateOnChange("centerHoMobile", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Center Image
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/jpg, image/jpeg, image/png"
                        onChange={(e) => handleImage1(e)}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-2">{$handleImage1}</div>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Center Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    value={updateData?.centerEmail}
                    onChange={(e) =>
                      handleUpdateOnChange("centerEmail", e.target.value)
                    }
                  />
                </div>
                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-thumbs-up mx-2"></i>Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Rest-password modal */}
      <div
        className="modal fade"
        id="resetkeymodal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Reset-Password
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handleResetPassword(e)}>
                <div className="mb-3">
                  <label className="col-form-label">Reset-password</label>
                  <input
                    type="text"
                    className="form-control"
                    value={resetKey}
                    onChange={(e) => setResetKey(e.target.value)}
                  />
                </div>

                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-key mx-2"></i>Reset
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Get student modal */}
      <div
        className="modal fade"
        id="getstudentmodal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Get Student Table
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <div>
                  <div className="table-responsive table-box">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-head">
                          <th>StudentFirstName</th>
                          {/* <th>StudentLastName</th> */}
                          <th>StudentFatherName</th>
                          <th>StudentDob</th>
                         
                          {/* <th>StudentMobile</th> */}
                          <th>StudentSessionStart</th>
                          <th>StudentSessionEnd</th>
                          <th>Add RollNo</th>
                          <th>Edit Center</th>
                          <th>Hall Ticket</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getSudentData.map((nft, index) => (
                          <tr key={index}>
                            <td>{nft?.StudentFirstName}</td>
                            {/* <td>{nft?.StudentLastName}</td> */}
                            <td>{nft?.StudentFatherName}</td>
                            <td>{nft?.StudentDob}</td>
                            <td>{nft?.StudentSessionStart}</td>
                            <td>{nft?.StudentSessionEnd}</td>
                            {/* <td>{nft?.StudentMobile}</td> */}
                            <td>
                              {" "}
                              <button
                                className="button-styles"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#addrollnomodal"
                                onClick={() => (
                                  setStudentId(nft._id), handlegetData(nft._id)
                                )}
                              >
                                <i className="fa-solid fa-key mx-2"></i>
                                Add Roll No
                              </button>
                            </td>

                            <td>
                              {" "}
                              <button
                                className="button-styles"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#editcentermodal"
                                onClick={() => setStudentId(nft._id)}
                              >
                                <i className="fa-solid fa-key mx-2"></i>
                                Edit Center
                              </button>
                            </td>

                            <td>
                              {" "}
                              <button
                                className="button-styles"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#hallticketmodal"
                                onClick={() => (
                                  setStudentId(nft._id),
                                  getHallTicketData(nft._id)
                                )}
                              >
                                <i className="fa-solid fa-key mx-2"></i>
                                Hall Ticket
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* pagenation  */}
                <div className="container">
                  <Paginate
                    pageCount={pageCount}
                    handlePageClick={handlePageClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Roll Number Add modal */}
      <div
        className="modal fade"
        id="addrollnomodal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Roll/DMC/Enrollment No
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form
                onSubmit={(e) => {
                  isEmpty
                    ? handleRollNumberData(e)
                    : handleRollNumberDataUpdate(e);
                }}
              >
                <div className="mb-3">
                  <label className="col-form-label">DMC Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={addRollData?.DmcNumber}
                    onChange={(e) =>
                      handleRollOnChange("DmcNumber", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label className="col-form-label">Roll Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={addRollData?.RollNumber}
                    onChange={(e) =>
                      handleRollOnChange("RollNumber", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label className="col-form-label">Enrollment Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={addRollData?.EnrollmentNumber}
                    onChange={(e) =>
                      handleRollOnChange("EnrollmentNumber", e.target.value)
                    }
                  />
                </div>

                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-key mx-2"></i>
                  {isEmpty ? "Submit" : " Update"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Center modal */}
      <div
        className="modal fade"
        id="editcentermodal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Center
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handleEditCenter(e)}>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Center
                  </label>

                  <select
                    className="form-control"
                    onChange={(e) => setNewCenter(e.target.value)}
                  >
                    <option value="" defaultChecked>
                      ---Select---
                    </option>
                    {getSubAdminData?.map((value, key) => {
                      return (
                        <option value={value?._id}>{value?.centerDist}</option>
                      );
                    })}
                  </select>
                </div>

                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-key mx-2"></i>Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Hall Ticket modal */}
      <div
        className="modal fade"
        id="hallticketmodal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Hall Ticket
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <section>
                <div className="container">
                  <div className="admit-card">
                    <div className="BoxA mar-bot">
                      <div className="row">
                        <div className="col-sm-2 hall-imgsection txt-center">
                          <img src={logo} alt="img-logo" />
                        </div>
                        <div className="col-sm-10 hall-datasection">
                          <h5>NFSSC</h5>
                          <p>National Fire & Safety Service College</p>
                          <span className="hall-ticket">HALL TICKET</span>
                        </div>
                      </div>
                    </div>
                    <div className="BoxD border- padding mar-bot">
                      <div className="row">
                        <div className="col-sm-10">
                          <div class="table-responsive">
                            <table className="table table-bordered">
                              <tbody>
                                <tr>
                                  <td>
                                    <b>
                                      ENROLLMENT NO :{" "}
                                      {hallTicketData[0]?.EnrollmentNumber}
                                    </b>
                                  </td>
                                  <td>
                                    <b>BRANCH CODE: </b> -
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>ROLL NO: </b>
                                    {hallTicketData[0]?.RollNumber}
                                  </td>
                                  <td>
                                    <b>Session: </b>-
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Student Name: </b>
                                    {
                                      hallTicketData[0]?.Students?.FirstName
                                    }{" "}
                                    {hallTicketData[0]?.Students?.LastName}
                                  </td>
                                  <td>
                                    <b>Father/Husband Name: </b>
                                    {hallTicketData[0]?.Students?.FatherName}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Course Name: </b>
                                    {hallTicketData[0]?.Course?.CourseFullName}
                                  </td>
                                  <td>
                                    <b>Exam Center: </b>-
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={2} style={{ height: 75 }}>
                                    <b>Location: </b>-
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-sm-2 txt-center">
                          <table className="table table-bordered">
                            <tbody>
                              <tr>
                                <th scope="row txt-center">
                                  <img
                                    src={`https://backend.nileshdawande.in/images/${hallTicketData[0]?.Students?.Picture}`}
                                    width="123px"
                                    height="165px"
                                    alt="img"
                                  />
                                </th>
                              </tr>
                              <tr>
                                <th scope="row txt-center">
                                  <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvZU2t1IiCr9Q_tmQin6nfsGFPt9aDE2bqqo2AuKxejQ&s"
                                    alt="img"
                                  />
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="BoxF border- padding mar-bot txt-center">
                      <div className="row">
                        <div className="col-sm-12">
                          <div class="table-responsive">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Sr.No.</th>
                                  <th>SubjectCode</th>
                                  <th>SubjectFullName</th>
                                  <th>Exam Date</th>
                                  <th>StartTime</th>
                                  <th>EndTime</th>
                                  <th>SubjectMarks</th>
                                </tr>
                              </thead>
                              <tbody>
                                {hallTicketData[0]?.Subjects?.map(
                                  (value, index) => {
                                    return (
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>{value?.SubjectCode}</td>
                                        <td className="text-start">
                                          {value?.SubjectFullName}
                                        </td>
                                        <td>{value?.StartDate}</td>
                                        <td>{value?.StartTime}</td>
                                        <td>{value?.EndTime}</td>
                                        <td>{value?.SubjectMarks}</td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="BoxD border- padding mar-bot">
                      <div className="row">
                        <div className="col-12">
                          <div className="instruction">
                            <h6>IMPORTANT INSTRUCTIONS FOR THE STUDENT</h6>
                            <p>
                              1. NO STUDENT/CANDIDATE WILL BE PERMITTED TO
                              APPEAR FOR THE EXAMINATION WITHOUT HALL TICKET AND
                              ID PROOF (ANY)
                            </p>
                            <p>
                              2. THE TIMING OF THE EXAMINATION IS 02:00 PM TO
                              05:00 PM. PLEASE OCCUPY YOUR SEAT AT LEAST 30
                              MINUTES BEFORE THE COMMENCEMENT OF THE EXAM
                            </p>
                            <p>
                              3. ENROLLMENT NO, REGISTRATION NO. & ANSWER SHEET
                              NO MUST BE FILLED VERY CAREFULLY IN ANSWER SHEET.
                              IN CASE OF ANY MISTAKE ASK FOR NEW ANSWER SHEET.
                              CAREFULLY MENTION THE ANSWER SHEET NO.4.
                            </p>

                            <p>
                              4. STUDENTS SHOULD MAINTAIN HIGH STANDARD OF
                              DISCIPLINE IN THE EXAMINATION HALL STUDENT SHOULD
                              READ ALL INSTRUCTIONS CAREFULLY
                            </p>
                            <p>
                              5. STUDENTS SHOULD NOT TALK IN THE EXAMINATION
                              HALL
                            </p>
                            <p>
                              6. DO NOT LEAVE THE EXAMINATION HALL DURING
                              EXAMINATION ALSO WITHOUT SUBMITTING ANSWER sheet.
                            </p>
                            <p>
                              7. Student who trying to indulge in any unfair
                              means shall be DEBARRED FROM THE EXAMINATION{" "}
                            </p>
                            <p>
                              8. IMPERSONATION USE OF UNFAIR MEANS SHALL LEAD TO
                              CANCELLATION OF THE CANDIDATURE
                            </p>
                            <p>
                              9. STUDENT FOUND WITH ANY LINAUTHORISED MATERIAL
                              WILL BE DEBARRED FROM THE EXAMINATION
                              (UNALUTHORISED MATERIAL LIKE-BOOKS, Notes,
                              Manuscripts, electronic media/Instruments)
                            </p>
                            <p>
                              10. FOR ANY TYPE OF QUERY/INCONVENIENCE, PLEASE
                              CONTACT US AT SUPPORT@AISECT.ORG
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer position="top-right" />
    </Layout>
  );
};
export default SubAdminPageSection;
