import React from "react";
import { FaTimes } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/images/finalLogo.jpeg";

const Sidebar = ({ togglesideBar, toggle }) => {
  const location = useLocation();
  const sideLinks = [
    {
      menu: "Dashboard",
      redirect: "dashboard",
      icon: "fa-solid fa-gauge",
    },
    {
      menu: "Course",
      redirect: "course",
      icon: "fa-solid fa-print",
    },
    {
      menu: "Subject",
      redirect: "subject",
      icon: "fa-solid fa-share",
    },
    {
      menu: "News",
      redirect: "news",
      icon: "fa-solid fa-layer-group",
    },
    {
      menu: "Sub-Admin",
      redirect: "sub-admin",
      icon: "fa-solid fa-layer-group",
    },
    {
      menu: "SubAdmin-Notification",
      redirect: "subadmin-notification",
      icon: "fa-solid fa-layer-group",
    },
    {
      menu: "Student Notification",
      redirect: "newstudent-notification",
      icon: "fa-solid fa-layer-group",
    },
    {
      menu: "Marksheet Type",
      redirect: "add-marksheet-type",
      icon: "fa-solid fa-layer-group",
    },
    {
      menu: "Invoice",
      redirect: "invoice",
      icon: "fa-solid fa-layer-group",
    },

    {
      menu: "Payment",
      redirect: "payment",
      icon: "fa-solid fa-layer-group",
    },
    {
      menu: "Payment Requies",
      redirect: "payment-requiest",
      icon: "fa-solid fa-layer-group",
    },
    {
      menu: "Teams",
      redirect: "team",
      icon: "fa-solid fa-layer-group",
    },
    {
      menu: "Placement",
      redirect: "admin-placement",
      icon: "fa-solid fa-layer-group",
    },
    {
      menu: "Time Table",
      redirect: "time-table",
      icon: "fa-solid fa-layer-group",
    },
    {
      menu: "Student Notification",
      redirect: "newstudent-notification",
      icon: "fa-solid fa-layer-group",
    },
  ];
  const isActive = (path) => {
    return location.pathname === path;
  };
  return (
    <div>
      <div className={`sidebar ${toggle && "show"}`}>
        <div className="toggle-btn close" onClick={togglesideBar}>
          <FaTimes />
        </div>
        <a href="/" className="logo">
          <img src={logo} alt="logo" className="w-75" />
          <span className="fs-4"> Admin Panel</span>
        </a>
        <hr />
        <ul className="nav nav-pills flex-column mb-auto">
          {sideLinks.map((data, index) => {
            return (
              <li key={index}>
                <Link
                  to={`/${data?.redirect}`}
                  activeClassName="active"
                  className={`nav-link link-body-emphasis ${
                    isActive(`/${data?.redirect}`) ? "active" : ""
                  }`}
                >
                  <i className={`${data?.icon} mx-2`}></i>
                  {data.menu}
                </Link>
              </li>
            );
          })}
        </ul>
        <hr />
      </div>
    </div>
  );
};

export default Sidebar;
