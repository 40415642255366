import React from "react";
import ReactPaginate from "react-paginate";
import styles from "./styles.css";
const index = ({ pagination, pageCount, handlePageClick }) => {
  //   const pageCount = pagination.totalPages;
  //   const currentPage = pagination.currentPage - 1;

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel=">>"
      onPageChange={handlePageClick}
      pageRangeDisplayed={1}
      pageCount={pageCount}
      previousLabel="<<"
      marginPagesDisplayed={2}
      renderOnZeroPageCount={null}
      //   forcePage={currentPage ? currentPage : null}
      forcePage={2}
      containerClassName={`pagination pagination_nav`}
      pageClassName={`page-item page_item_cus`}
      pageLinkClassName={`page-link page_link_nav`}
      previousClassName={`page-item page_item_cus`}
      previousLinkClassName={`page-link navprev`}
      nextClassName={`page-item page_item_cus`}
      nextLinkClassName={`page-link next`}
      breakClassName={`page-item page_item_cus`}
      breakLinkClassName={`page-link page_link_nav`}
      activeClassName={`page_active`}
    />
  );
};

export default index;
