import React from "react";
import Layout from "../Layout";

const DashboardPagSectoin = () => {
  const dummyAssets = [
    {
      courseId: "1",
      courseName: "Digital Art",
    },
    {
      courseId: "2",
      courseName: "Digital Art",
    },
    {
      courseId: "3",
      courseName: "Digital Art",
    },
  ];

  return (
    <Layout>
      <div className="container">
        <div className="row my-4">
          <div className="col-12 col-md-3">
            <div className="card cus-card">
              <div className="card-header text-center ">Total Student</div>
              <div className="card-body">
                <h5 className="text-center">100</h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="card cus-card">
              <div className="card-header text-center">Active Student</div>
              <div className="card-body">
                <h5 className="text-center">100</h5>
              </div>
            </div>
          </div>{" "}
          <div className="col-12 col-md-3">
            <div className="card cus-card">
              <div className="card-header text-center">InActive Student</div>
              <div className="card-body">
                <h5 className="text-center">100</h5>
              </div>
            </div>
          </div>{" "}
          <div className="col-12 col-md-3">
            <div className="card cus-card">
              <div className="card-header text-center">Block Student</div>
              <div className="card-body">
                <h5 className="text-center">100</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="row my-4">
          <div className="col-12 col-md-3">
            <div className="card cus-card">
              <div className="card-header text-center ">Total Student</div>
              <div className="card-body">
                <h5 className="text-center">100</h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="card cus-card">
              <div className="card-header text-center">Active Student</div>
              <div className="card-body">
                <h5 className="text-center">100</h5>
              </div>
            </div>
          </div>{" "}
          <div className="col-12 col-md-3">
            <div className="card cus-card">
              <div className="card-header text-center">InActive Student</div>
              <div className="card-body">
                <h5 className="text-center">100</h5>
              </div>
            </div>
          </div>{" "}
          <div className="col-12 col-md-3">
            <div className="card cus-card">
              <div className="card-header text-center">Block Student</div>
              <div className="card-body">
                <h5 className="text-center">100</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-12">
            <div className="card ">
              <div className="card-header">
                <h5 className="fw-bold">Student Details</h5>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <div>
                    <div className="table-responsive table-box">
                      <table className="table table-bordered">
                        <thead>
                          <tr className="table-head">
                            <th>Course Id</th>
                            <th>Course Name</th>
                            <th>Edit</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dummyAssets.map((nft, index) => (
                            <tr key={nft.courseId}>
                              <td>{nft.courseId}</td>
                              <td>{nft.courseName}</td>
                              <td>
                                <button
                                  className="button-styles"
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#editcourse"
                                >
                                  <i class="fa-solid fa-pen-to-square mx-2"></i>
                                  Edit
                                </button>
                              </td>
                              <td>
                                <button
                                  className="button-styles"
                                  type="button"
                                  // onClick={() => handleDelete()}
                                >
                                  <i class="fa-solid fa-trash mx-2"></i>
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DashboardPagSectoin;
