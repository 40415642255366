import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { FiSearch } from "react-icons/fi";
import Swal from "sweetalert2";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles.css";
const TeamPageSection = () => {
  const [getAllTeamData, setgetAllTeamData] = useState([]);
  const [updateId, setUpdateId] = useState("");
  const [loading, setLoading] = useState(false);
  const [image1file, setImage1file] = useState("");
  const [image1fileUrl, setImage1fileUrl] = useState("");
  const [teamData, setTeamData] = useState({
    DesignationName: "",
    FullName: "",
    MobileNumber: "",
    FacebookPage: "",
    InstagramPage: "",
    LinkeDinPage: "",
    ProfilePic: "",
  });

  const [teamUpdateData, setTeamUpdateData] = useState({
    DesignationName: "",
    FullName: "",
    MobileNumber: "",
    FacebookPage: "",
    InstagramPage: "",
    LinkeDinPage: "",
    ProfilePic: "",
  });

  const handleOnChange = (key, value) => {
    setTeamData((teamData) => ({
      ...teamData,
      [key]: value,
    }));
  };

  const handleUpdateOnChange = (key, value) => {
    setTeamUpdateData((teamUpdateData) => ({
      ...teamUpdateData,
      [key]: value,
    }));
  };

  const handleDelete = (id) => {
    debugger;
    Swal.fire({
      text: "Are you sure you won't be deleted?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const setDeleteTeamData = await axios.delete(
            `api/admin/delete-team/${id}`
          );
          if (setDeleteTeamData.data.isTeamDeleted === true) {
            toast.success(setDeleteTeamData.data.msg);
            handleGetTeamData();
          }
        } catch (error) {
          if (error.response.status === 400) {
            const message_400 = error?.response?.data?.message;
            toast.error(message_400);
          } else if (error.response.status === 401) {
            const message_401 = error?.response?.data?.message;
            toast.error(message_401);
          } else if (error.response.status === 404) {
            const message_404 = error?.response?.data?.message;
            console.log("message_404", message_404);
            toast.error(message_404);
          } else if (error.response.status === 500) {
            const message_500 = error?.response?.data?.message;
            toast.error(message_500);
          } else {
            toast.error("something went wrong");
          }
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("Cancelled");
      }
    });
  };
  const handleGetTeamData = async () => {
    try {
      const getTeam = await axios.get("api/admin/get-team");
      if (getTeam?.data?.isTeamFound === true) {
        setgetAllTeamData(getTeam?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };
  const handlePostTeamData = async (e) => {
    debugger;
    e.preventDefault();
    try {
      const setTemasData = await axios.post("api/admin/create-team", teamData);
      if (setTemasData?.data?.isTeamCreated === true) {
        toast.success(setTemasData?.data?.msg);
        window.location.reload();
        handleGetTeamData();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleUpdateCourseData = async (e) => {
    debugger;
    e.preventDefault();
    try {
      const setUpdateCourseData = await axios.put(
        `api/admin/update-team/${updateId}`,
        teamUpdateData
      );
      if (setUpdateCourseData.data.isTeamUpdated === true) {
        toast.success(setUpdateCourseData.data.msg);
        handleGetTeamData();
        window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  //   handle image function

  const handleImage1 = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];
    if (newFile !== undefined) {
      reader.onloadend = async () => {
        setLoading(true);
        try {
          if (newFile.size > 1024 * 1024) {
            alert("Image size should be less then 1 Mb");
          } else {
            const formdata = new FormData();
            formdata.append("Images", newFile);
            const resp = await axios.post(`api/upload/images`, formdata);
            if (resp.status === 200) {
              const uploadedImg = resp.data.image;
              setImage1file(uploadedImg);
              setImage1fileUrl(reader.result);
              handleOnChange("ProfilePic", uploadedImg);
              handleUpdateOnChange("ProfilePic", uploadedImg);
              toast.success(resp?.data?.message);
            } else {
              toast.error("Error");
            }
          }
        } catch (error) {
          if (error.response.status === 400) {
            var err_400 = error?.response?.data?.message;
            alert(err_400);
          } else if (error.response.status === 401) {
            const err_401 = error?.response?.data?.message;
            alert(err_401);
          } else if (error.response.status === 500) {
            const err_500 = error?.response?.data?.message;
            alert(err_500);
          } else {
            alert("error in News one image");
            console.log("error in News one image", error);
          }
        }
        setLoading(false);
      };
      reader.readAsDataURL(newFile);
    } else {
      setImage1fileUrl(null);
    }
  };

  let $handleImage1 = null;
  if (image1fileUrl) {
    $handleImage1 = (
      <img src={image1fileUrl} alt="Preview" className="news-img" />
    );
  } else {
    $handleImage1 = (
      <div className="previewText">Please select Image for Preview</div>
    );
  }

  console.log("teamData", teamData);
  useEffect(() => {
    handleGetTeamData();
  }, []);
  return (
    <Layout>
      <form className="d-flex mb-3 my-3">
        <div className="row w-100 justify-content-between g-3">
          <div className="col-lg-5 col-md-6 col-sm-12">
            <div className="input-group ">
              <input
                type="text"
                className="form-control"
                placeholder="Search Address"
                aria-label="Search"
                aria-describedby="button-addon2"
              />
              <button
                className="button-styles"
                type="button"
                id="button-addon2"
              >
                <FiSearch />
              </button>
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-4 col-sm-12">
            <div className="text-end">
              <select className="form-select">
                <option defaultValue value="All">
                  All
                </option>
                <option value="Mega">First Year</option>
                <option value="Large">Second Year</option>
                <option value="Medium">Third Year</option>
              </select>
            </div>
          </div> */}
        </div>
      </form>
      <button
        type="button"
        className="button-styles my-3"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        data-bs-whatever="@mdo"
      >
        <i class="fa-solid fa-plus mx-2"></i>
        Add Team
      </button>
      <div className="table-responsive">
        <div>
          <div className="table-responsive table-box">
            <table className="table table-bordered">
              <thead>
                <tr className="table-head">
                  <th>Team Id</th>
                  <th>DesignationName</th>
                  <th>FullName</th>
                  <th>MobileNumber</th>
                  <th>FacebookPage</th>
                  <th>InstagramPage</th>
                  <th>LinkeDinPage</th>
                  <th>ProfilePic</th>
                  <th>createdAt</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {getAllTeamData.map((nft, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{nft.DesignationName}</td>
                    <td>{nft.FullName}</td>
                    <td>{nft.MobileNumber}</td>
                    <td>{nft.FacebookPage}</td>
                    <td>{nft.InstagramPage}</td>
                    <td>{nft.LinkeDinPage}</td>
                    <td>
                      <div className="tbl-img">
                        <img
                          src={`https://backend.nileshdawande.in/images/${nft?.ProfilePic}`}
                          alt="img1"
                        />
                      </div>
                    </td>
                    <td>{nft.createdAt}</td>

                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#editcourse"
                        onClick={() => (
                          setTeamUpdateData({
                            DesignationName: nft?.DesignationName,
                            FullName: nft?.FullName,
                            MobileNumber: nft?.MobileNumber,
                            FacebookPage: nft?.FacebookPage,
                            InstagramPage: nft?.InstagramPage,
                            LinkeDinPage: nft?.LinkeDinPage,
                            ProfilePic: nft?.ProfilePic,
                          }),
                          setUpdateId(nft._id)
                        )}
                      >
                        <i class="fa-solid fa-pen-to-square mx-2"></i>
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        onClick={() => handleDelete(nft._id)}
                      >
                        <i class="fa-solid fa-trash mx-2"></i>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/*Add modal form */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Team
              </h5>
              <button
                type="button"
                className="btn-close cus-btn-close "
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handlePostTeamData(e)}>
                <div className="mb-2">
                  <label className="col-form-label">Designation Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={teamData.DesignationName}
                    onChange={(e) =>
                      handleOnChange("DesignationName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label className="col-form-label">FullName</label>
                  <input
                    type="text"
                    className="form-control"
                    value={teamData.FullName}
                    onChange={(e) => handleOnChange("FullName", e.target.value)}
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Mobile Number
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={teamData.MobileNumber}
                    onChange={(e) =>
                      handleOnChange("MobileNumber", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label className="col-form-label">Facebook Link</label>
                  <input
                    type="text"
                    className="form-control"
                    value={teamData.FacebookPage}
                    onChange={(e) =>
                      handleOnChange("FacebookPage", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Instagram Link
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={teamData.InstagramPage}
                    onChange={(e) =>
                      handleOnChange("InstagramPage", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Linkedin Link
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={teamData.LinkeDinPage}
                    onChange={(e) =>
                      handleOnChange("LinkeDinPage", e.target.value)
                    }
                  />
                </div>
                {/* <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    ProfilePic
                  </label>
                  <input type="file" className="form-control" />
                </div> */}

                <div className="mb-2">
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <label className="col-form-label">ProfilePic</label>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/jpg, image/jpeg, image/png"
                        onChange={(e) => handleImage1(e)}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-2">{$handleImage1}</div>
                    </div>
                  </div>
                </div>
                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-thumbs-up mx-2"></i>Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/*Edit  modal form */}
      <div
        className="modal fade"
        id="editcourse"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Course
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handleUpdateCourseData(e)}>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Designation Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={teamUpdateData.DesignationName}
                    onChange={(e) =>
                      handleUpdateOnChange("DesignationName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={teamUpdateData.FullName}
                    onChange={(e) =>
                      handleUpdateOnChange("FullName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Mobile Number
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={teamUpdateData.MobileNumber}
                    onChange={(e) =>
                      handleUpdateOnChange("MobileNumber", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Facebook Link
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={teamUpdateData.FacebookPage}
                    onChange={(e) =>
                      handleUpdateOnChange("FacebookPage", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Instagram Link
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={teamUpdateData.InstagramPage}
                    onChange={(e) =>
                      handleUpdateOnChange("InstagramPage", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    LinkeDin Link
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={teamUpdateData.LinkeDinPage}
                    onChange={(e) =>
                      handleUpdateOnChange("LinkeDinPage", e.target.value)
                    }
                  />
                </div>

                <div className="mb-2">
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <label className="col-form-label">ProfilePic</label>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/jpg, image/jpeg, image/png"
                        onChange={(e) => handleImage1(e)}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-2">{$handleImage1}</div>
                    </div>
                  </div>
                </div>
                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-thumbs-up mx-2"></i>Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer position="top-right" />
    </Layout>
  );
};
export default TeamPageSection;
