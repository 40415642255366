import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "./component/Pages/LoginPage/index";
import DashboardPage from "./component/Pages/DashboardPage";
import CoursePage from "./component/Pages/CoursePage";
import SubjectPage from "./component/Pages/SubjectPage";
import SubAdminPage from "./component/Pages/SubAdminPage";
import axios from "axios";
import NewsPage from "./component/Pages/NewsPageSectionNewsPageSectionNewsPageSection";
import SubAdminNotificationPage from "./component/Pages/SubAdminNotificationPage";
import InvoicePage from "./component/Pages/InvoicePage";
import MarksheetType from "./component/Pages/MarksheetType";
import PaymentPage from "./component/Pages/PaymentPage";
import PaymentRequiestPage from "./component/Pages/PaymentRequiestPage";
import TeamPage from "./component/Pages/TeamPage";
import TimeTablePage from "./component/Pages/TimeTablePage";
import PlacementPage from "./component/Pages/PlacementPage";
import StudentNotification from "./component/Pages/StudentNotification";
function App() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  useEffect(() => {
    const verifyAuthToken = async () => {
      try {
        const response = await axios.get(`api/centers/verify-admin`, {});
      } catch (error) {
        navigate("/");
      }
    };

    verifyAuthToken();
  }, [navigate]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<DashboardPage />} />
      <Route path="/course" element={<CoursePage />} />
      <Route path="/subject" element={<SubjectPage />} />
      <Route path="/sub-admin" element={<SubAdminPage />} />
      <Route path="/news" element={<NewsPage />} />
      <Route path="/add-marksheet-type" element={<MarksheetType />} />
      <Route
        path="/subadmin-notification"
        element={<SubAdminNotificationPage />}
      />
        <Route
        path="/newstudent-notification"
        element={<StudentNotification />}
      />
      <Route path="/invoice" element={<InvoicePage />} />
      <Route path="/payment" element={<PaymentPage />} />
      <Route path="/payment-requiest" element={<PaymentRequiestPage />} />
      <Route path="/team" element={<TeamPage />} />
      <Route path="/time-table" element={<TimeTablePage />} />
      <Route path="/admin-placement" element={<PlacementPage />} />
      <Route path="/admin-placement" element={<PlacementPage />} />
    </Routes>
  );
}

export default App;
