import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { FiSearch } from "react-icons/fi";
import Swal from "sweetalert2";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles.css";
const PlacementPageSection = () => {
  const [getAllplacementData, setgetAllplacementData] = useState([]);
  const [updateId, setUpdateId] = useState("");
  const [loading, setLoading] = useState(false);
  const [image1file, setImage1file] = useState("");
  const [image1fileUrl, setImage1fileUrl] = useState("");
  const [teamData, setTeamData] = useState({
    ComanyName: "",
    ComanyNumber: "",
    ComanyAddress: "",
    PostName: "",
    NumberOfPost: "",
  });

  const [teamUpdateData, setTeamUpdateData] = useState({
    ComanyName: "",
    ComanyNumber: "",
    ComanyAddress: "",
    PostName: "",
    NumberOfPost: "",
  });

  const handleOnChange = (key, value) => {
    setTeamData((teamData) => ({
      ...teamData,
      [key]: value,
    }));
  };

  const handleUpdateOnChange = (key, value) => {
    setTeamUpdateData((teamUpdateData) => ({
      ...teamUpdateData,
      [key]: value,
    }));
  };

  const handleDelete = (id) => {
    Swal.fire({
      text: "Are you sure you won't be deleted?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const setDeleteTeamData = await axios.delete(
            `api/admin/delete-placement/${id}`
          );
          if (setDeleteTeamData.data.isPlacementDeleted === true) {
            toast.success(setDeleteTeamData.data.msg);
            handleGetPlacementData();
          }
        } catch (error) {
          if (error.response.status === 400) {
            const message_400 = error?.response?.data?.message;
            toast.error(message_400);
          } else if (error.response.status === 401) {
            const message_401 = error?.response?.data?.message;
            toast.error(message_401);
          } else if (error.response.status === 404) {
            const message_404 = error?.response?.data?.message;
            console.log("message_404", message_404);
            toast.error(message_404);
          } else if (error.response.status === 500) {
            const message_500 = error?.response?.data?.message;
            toast.error(message_500);
          } else {
            toast.error("something went wrong");
          }
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("Cancelled");
      }
    });
  };
  const handleGetPlacementData = async () => {
    try {
      const getPlacement = await axios.get("api/admin/get-placement");
      if (getPlacement?.data?.isPlacementFound === true) {
        setgetAllplacementData(getPlacement?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handlePostTeamData = async (e) => {
    e.preventDefault();
    try {
      const setTemasData = await axios.post(
        "api/admin/create-placement",
        teamData
      );
      if (setTemasData?.data?.isPlacementCreated === true) {
        toast.success(setTemasData?.data?.msg);
        window.location.reload();
        handleGetPlacementData();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleUpdateCourseData = async (e) => {
    e.preventDefault();
    try {
      const setUpdateCourseData = await axios.put(
        `api/admin/update-placement/${updateId}`,
        teamUpdateData
      );
      if (setUpdateCourseData.data.isPlacementUpdated === true) {
        toast.success(setUpdateCourseData.data.msg);
        handleGetPlacementData();
        window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  useEffect(() => {
    handleGetPlacementData();
  }, []);
  return (
    <Layout>
      <form className="d-flex mb-3 my-3">
        <div className="row w-100 justify-content-between g-3">
          <div className="col-lg-5 col-md-6 col-sm-12">
            <div className="input-group ">
              <input
                type="text"
                className="form-control"
                placeholder="Search Address"
                aria-label="Search"
                aria-describedby="button-addon2"
              />
              <button
                className="button-styles"
                type="button"
                id="button-addon2"
              >
                <FiSearch />
              </button>
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-4 col-sm-12">
            <div className="text-end">
              <select className="form-select">
                <option defaultValue value="All">
                  All
                </option>
                <option value="Mega">First Year</option>
                <option value="Large">Second Year</option>
                <option value="Medium">Third Year</option>
              </select>
            </div>
          </div> */}
        </div>
      </form>
      <button
        type="button"
        className="button-styles my-3"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        data-bs-whatever="@mdo"
      >
        <i class="fa-solid fa-plus mx-2"></i>
        Add Team
      </button>
      <div className="table-responsive">
        <div>
          <div className="table-responsive table-box">
            <table className="table table-bordered">
              <thead>
                <tr className="table-head">
                  <th>Placement Id</th>
                  <th>PostName</th>
                  <th>ComanyName</th>
                  <th>ComanyNumber</th>
                  <th>ComanyAddress</th>
                  <th>NumberOfPost</th>

                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {getAllplacementData.map((nft, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{nft.PostName}</td>
                    <td>{nft.ComanyName}</td>
                    <td>{nft.ComanyNumber}</td>
                    <td>{nft.ComanyAddress}</td>
                    <td>{nft.NumberOfPost}</td>
                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#editcourse"
                        onClick={() => (
                          setTeamUpdateData({
                            ComanyName: nft?.ComanyName,
                            ComanyNumber: nft?.ComanyNumber,
                            ComanyAddress: nft?.ComanyAddress,
                            PostName: nft?.PostName,
                            NumberOfPost: nft?.NumberOfPost,
                          }),
                          setUpdateId(nft._id)
                        )}
                      >
                        <i class="fa-solid fa-pen-to-square mx-2"></i>
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        onClick={() => handleDelete(nft._id)}
                      >
                        <i class="fa-solid fa-trash mx-2"></i>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/*Add modal form */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Placement
              </h5>
              <button
                type="button"
                className="btn-close cus-btn-close "
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handlePostTeamData(e)}>
                <div className="mb-2">
                  <label className="col-form-label">Comany Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={teamData.ComanyName}
                    onChange={(e) =>
                      handleOnChange("ComanyName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label className="col-form-label">Comany Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={teamData.ComanyNumber}
                    onChange={(e) =>
                      handleOnChange("ComanyNumber", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Comany Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={teamData.ComanyAddress}
                    onChange={(e) =>
                      handleOnChange("ComanyAddress", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label className="col-form-label">Post Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={teamData.PostName}
                    onChange={(e) => handleOnChange("PostName", e.target.value)}
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Number Of Post
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={teamData.NumberOfPost}
                    onChange={(e) =>
                      handleOnChange("NumberOfPost", e.target.value)
                    }
                  />
                </div>

                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-thumbs-up mx-2"></i>Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/*Edit  modal form */}
      <div
        className="modal fade"
        id="editcourse"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Course
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handleUpdateCourseData(e)}>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Comany Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={teamUpdateData.ComanyName}
                    onChange={(e) =>
                      handleUpdateOnChange("ComanyName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    ComanyNumber
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={teamUpdateData.ComanyNumber}
                    onChange={(e) =>
                      handleUpdateOnChange("ComanyNumber", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Comany Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={teamUpdateData.ComanyAddress}
                    onChange={(e) =>
                      handleUpdateOnChange("ComanyAddress", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Post Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={teamUpdateData.PostName}
                    onChange={(e) =>
                      handleUpdateOnChange("PostName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Number Of Post
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={teamUpdateData.NumberOfPost}
                    onChange={(e) =>
                      handleUpdateOnChange("NumberOfPost", e.target.value)
                    }
                  />
                </div>

                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-thumbs-up mx-2"></i>Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer position="top-right" />
    </Layout>
  );
};
export default PlacementPageSection;
