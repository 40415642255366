import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const LoginPageSection = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== "" && email !== "") {
      try {
        const payload = {
          Email: email,
          Password: password,
        };
        let resp = await axios.post(`api/admin/login`, payload);
        console.log("nfsscresp", resp);
        resp = resp.data;
        if (resp.isLogged === true) {
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${resp.token}`;
          localStorage.setItem("token", resp.token);
          navigate("/dashboard", { state: { isUserLoggedIn: true } });
          toast.success(resp?.message);
        }
      } catch (error) {
        if (error.response.status === 400) {
          const message_400 = error?.response?.data?.message;
          toast.error(message_400);
        } else if (error.response.status === 401) {
          const message_401 = error?.response?.data?.message;
          console.log("message_401", message_401);
          toast.error(message_401);
        } else if (error.response.status === 500) {
          const message_500 = error?.response?.data?.message;
          toast.error(message_500);
        } else {
          toast.error("something went wrong");
        }
      }
    } else {
      toast.error("Invalid Credentials!!!!");
    }
  };
  return (
    <>
      <div className=" container-fluid vh-100">
        <div className="mt-5 main_background">
          <div className="rounded d-flex justify-content-center">
            <div className="col-md-4 col-sm-12 shadow-lg p-5 bg-light">
              <div className="text-center">
                <h3 className="">Sign In</h3>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="p-4">
                  <div className="input-group mb-3">
                    <span className="input-group-text darkbg">
                      <i className="bi bi-person-plus-fill text-dark" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      placeholder="Email"
                    />
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-text darkbg">
                      <i className="bi bi-key-fill text-dark" />
                    </span>
                    <input
                      type={passwordShown ? "text" : "password"}
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      placeholder="password"
                    />
                    <button
                      className="button-styles"
                      onClick={togglePasswordVisibility}
                      type="button"
                    >
                      {passwordShown ? (
                        <i className="fa-regular fa-eye-slash"></i>
                      ) : (
                        <i className="fa-regular fa-eye"></i>
                      )}
                    </button>
                  </div>
                  <div className="form-check my-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Remember Me
                    </label>
                  </div>
                  <div className="text-center">
                    <button
                      className="button-styles text-center my-4"
                      type="submit"
                      style={{ width: "120px" }}
                    >
                      Login
                    </button>
                  </div>
                  {/* <p className="text-center ">Don't have an account?
                    <Link to='/signup' className=""> Sign Up</Link>
                  </p> */}
                  <p className="text-center ">Forgot your password?</p>
                </div>
              </form>
            </div>
          </div>{" "}
          <ToastContainer position="top-right" />
        </div>
      </div>
    </>
  );
};

export default LoginPageSection;
