import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { FiSearch } from "react-icons/fi";
import Swal from "sweetalert2";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles.css";
const NewsPageSection = () => {
  const [getAllNewsData, setGetAllNewsData] = useState([]);
  const [image1file, setImage1file] = useState("");
  const [image1fileUrl, setImage1fileUrl] = useState("");

  const [image2file, setImage2file] = useState("");
  const [image2fileUrl, setImage2fileUrl] = useState("");

  const [image3file, setImage3file] = useState("");
  const [image3fileUrl, setImage3fileUrl] = useState("");

  const [image4file, setImage4file] = useState("");
  const [image4fileUrl, setImage4fileUrl] = useState("");

  const [loading, setLoading] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [newsData, setnewsData] = useState({
    NewsTitle: "",
    NewsDesc: "",
    ImageOne: "",
    ImageTwo: "",
    ImageThree: "",
    ImageFour: "",
  });

  const [newsUpdateData, setNewsUpdateData] = useState({
    NewsTitle: "",
    NewsDesc: "",
    ImageOne: "",
    ImageTwo: "",
    ImageThree: "",
    ImageFour: "",
  });

  const handleOnChange = (key, value) => {
    setnewsData((newsData) => ({
      ...newsData,
      [key]: value,
    }));
  };

  const handleUpdateOnChange = (key, value) => {
    setNewsUpdateData((newsUpdateData) => ({
      ...newsUpdateData,
      [key]: value,
    }));
  };

  const handleDelete = (id) => {
    Swal.fire({
      text: "Are you sure you won't be deleted?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const setDeletenewsData = await axios.delete(
            `api/admin/delete-news/${id}`
          );
          if (setDeletenewsData.data.isNewsDeleted === true) {
            toast.success(setDeletenewsData.data.msg);
            handleGetNewsData();
          }
        } catch (error) {
          if (error.response.status === 400) {
            const message_400 = error?.response?.data?.message;
            toast.error(message_400);
          } else if (error.response.status === 401) {
            const message_401 = error?.response?.data?.message;
            toast.error(message_401);
          } else if (error.response.status === 404) {
            const message_404 = error?.response?.data?.message;
            console.log("message_404", message_404);
            toast.error(message_404);
          } else if (error.response.status === 500) {
            const message_500 = error?.response?.data?.message;
            toast.error(message_500);
          } else {
            toast.error("something went wrong");
          }
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("Cancelled");
      }
    });
  };
  const handleGetNewsData = async () => {
    try {
      const getNews = await axios.get("api/admin/get-news");
      if (getNews?.data?.isNewsFound === true) {
        setGetAllNewsData(getNews?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };
  const handlePostnewsData = async (e) => {
    e.preventDefault();
    try {
      const setnewsData = await axios.post("api/admin/add-news", newsData);

      handleGetNewsData();
      if (setnewsData?.data?.isNewsCreated === true) {
        toast.success(setnewsData?.data?.msg);
        window.location.reload();
        handleGetNewsData();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
    console.log("news Data", newsData);
  };

  const handleUpdatenewsData = async (e) => {
    e.preventDefault();
    try {
      const setUpdatenewsData = await axios.put(
        `api/admin/update-news/${updateId}`,
        newsUpdateData
      );
      console.log("setUpdatenewsData", setUpdatenewsData);
      if (setUpdatenewsData.data.isNewsUpdated === true) {
        toast.success(setUpdatenewsData.data.msg);
        handleGetNewsData();
        window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
    console.log("newsUpdateData", newsUpdateData);
  };

  //   handle image function

  const handleImage1 = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];
    if (newFile !== undefined) {
      reader.onloadend = async () => {
        setLoading(true);
        try {
          if (newFile.size > 1024 * 1024) {
            alert("Image size should be less then 1 Mb");
          } else {
            const formdata = new FormData();
            formdata.append("Images", newFile);
            const resp = await axios.post(`api/upload/images`, formdata);
            if (resp.status === 200) {
              const uploadedImg = resp.data.image;
              setImage1file(uploadedImg);
              setImage1fileUrl(reader.result);
              handleOnChange("ImageOne", uploadedImg);
              handleUpdateOnChange("ImageOne", uploadedImg);
              toast.success(resp?.data?.message);
            } else {
              toast.error("Error");
            }
          }
        } catch (error) {
          if (error.response.status === 400) {
            var err_400 = error?.response?.data?.message;
            alert(err_400);
          } else if (error.response.status === 401) {
            const err_401 = error?.response?.data?.message;
            alert(err_401);
          } else if (error.response.status === 500) {
            const err_500 = error?.response?.data?.message;
            alert(err_500);
          } else {
            alert("error in News one image");
            console.log("error in News one image", error);
          }
        }
        setLoading(false);
      };
      reader.readAsDataURL(newFile);
    } else {
      setImage1fileUrl(null);
    }
  };

  const handleImage2 = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];
    if (newFile !== undefined) {
      reader.onloadend = async () => {
        setLoading(true);
        try {
          if (newFile.size > 1024 * 1024) {
            alert("Image size should be less then 1 Mb");
          } else {
            const formdata = new FormData();
            formdata.append("Images", newFile);
            const resp = await axios.post(`api/upload/images`, formdata);
            if (resp.status === 200) {
              const uploadedImg = resp.data.image;
              setImage2file(uploadedImg);
              setImage2fileUrl(reader.result);
              handleOnChange("ImageTwo", uploadedImg);
              handleUpdateOnChange("ImageTwo", uploadedImg);
              toast.success(resp?.data?.message);
            } else {
              toast.error("Error");
            }
          }
        } catch (error) {
          if (error.response.status === 400) {
            var err_400 = error?.response?.data?.message;
            alert(err_400);
          } else if (error.response.status === 401) {
            const err_401 = error?.response?.data?.message;
            alert(err_401);
          } else if (error.response.status === 500) {
            const err_500 = error?.response?.data?.message;
            alert(err_500);
          } else {
            alert("error in News one image");
            console.log("error in News one image", error);
          }
        }
        setLoading(false);
      };
      reader.readAsDataURL(newFile);
    } else {
      setImage2fileUrl(null);
    }
  };

  const handleImage3 = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];
    if (newFile !== undefined) {
      reader.onloadend = async () => {
        setLoading(true);
        try {
          if (newFile.size > 1024 * 1024) {
            alert("Image size should be less then 1 Mb");
          } else {
            const formdata = new FormData();
            formdata.append("Images", newFile);
            const resp = await axios.post(`api/upload/images`, formdata);
            if (resp.status === 200) {
              const uploadedImg = resp.data.image;
              setImage3file(uploadedImg);
              setImage3fileUrl(reader.result);
              handleOnChange("ImageThree", uploadedImg);
              handleUpdateOnChange("ImageThree", uploadedImg);
              toast.success(resp?.data?.message);
            } else {
              toast.error("Error");
            }
          }
        } catch (error) {
          if (error.response.status === 400) {
            var err_400 = error?.response?.data?.message;
            alert(err_400);
          } else if (error.response.status === 401) {
            const err_401 = error?.response?.data?.message;
            alert(err_401);
          } else if (error.response.status === 500) {
            const err_500 = error?.response?.data?.message;
            alert(err_500);
          } else {
            alert("error in News one image");
            console.log("error in News one image", error);
          }
        }
        setLoading(false);
      };
      reader.readAsDataURL(newFile);
    } else {
      setImage3fileUrl(null);
    }
  };

  const handleImage4 = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];
    if (newFile !== undefined) {
      reader.onloadend = async () => {
        setLoading(true);
        try {
          if (newFile.size > 1024 * 1024) {
            alert("Image size should be less then 1 Mb");
          } else {
            const formdata = new FormData();
            formdata.append("Images", newFile);
            const resp = await axios.post(`api/upload/images`, formdata);
            if (resp.status === 200) {
              const uploadedImg = resp.data.image;
              setImage4file(uploadedImg);
              setImage4fileUrl(reader.result);
              handleOnChange("ImageFour", uploadedImg);
              handleUpdateOnChange("ImageFour", uploadedImg);
              toast.success(resp?.data?.message);
            } else {
              toast.error("Error");
            }
          }
        } catch (error) {
          if (error.response.status === 400) {
            var err_400 = error?.response?.data?.message;
            alert(err_400);
          } else if (error.response.status === 401) {
            const err_401 = error?.response?.data?.message;
            alert(err_401);
          } else if (error.response.status === 500) {
            const err_500 = error?.response?.data?.message;
            alert(err_500);
          } else {
            alert("error in News one image");
            console.log("error in News one image", error);
          }
        }
        setLoading(false);
      };
      reader.readAsDataURL(newFile);
    } else {
      setImage4fileUrl(null);
    }
  };

  let $handleImage1 = null;
  if (image1fileUrl) {
    $handleImage1 = (
      <img src={image1fileUrl} alt="Preview" className="news-img" />
    );
  } else {
    $handleImage1 = (
      <div className="previewText">Please select Image for Preview</div>
    );
  }

  let $handleImage2 = null;
  if (image2fileUrl) {
    $handleImage2 = (
      <img src={image2fileUrl} alt="Preview" className="news-img" />
    );
  } else {
    $handleImage2 = (
      <div className="previewText">Please select Image for Preview</div>
    );
  }

  let $handleImage3 = null;
  if (image1fileUrl) {
    $handleImage3 = (
      <img src={image3fileUrl} alt="Preview" className="news-img" />
    );
  } else {
    $handleImage3 = (
      <div className="previewText">Please select Image for Preview</div>
    );
  }

  let $handleImage4 = null;
  if (image4fileUrl) {
    $handleImage4 = (
      <img src={image4fileUrl} alt="Preview" className="news-img" />
    );
  } else {
    $handleImage4 = (
      <div className="previewText">Please select Image for Preview</div>
    );
  }
  // ----
  console.log("updateId", updateId);
  useEffect(() => {
    handleGetNewsData();
  }, []);
  return (
    <Layout>
      <form className="d-flex mb-3 my-3">
        <div className="row w-100 justify-content-between g-3">
          <div className="col-lg-5 col-md-6 col-sm-12">
            <div className="input-group ">
              <input
                type="text"
                className="form-control"
                placeholder="Search Address"
                aria-label="Search"
                aria-describedby="button-addon2"
              />
              <button
                className="button-styles"
                type="button"
                id="button-addon2"
              >
                <FiSearch />
              </button>
            </div>
          </div>
        </div>
      </form>
      <button
        type="button"
        className="button-styles my-3"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        data-bs-whatever="@mdo"
      >
        <i class="fa-solid fa-plus mx-2"></i>
        Add News
      </button>
      <div className="table-responsive">
        <div>
          <div className="table-responsive table-box">
            <table className="table table-bordered">
              <thead>
                <tr className="table-head">
                  <th>News Id</th>
                  <th>News Title</th>
                  <th>NewsDesc</th>
                  <th>Images1</th>
                  <th>Images2</th>
                  <th>Images3</th>
                  <th>Images4</th>
                  <th>createdAt</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {getAllNewsData.map((nft, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{nft.NewsTitle}</td>
                    <td>{nft.NewsDesc}</td>
                    <td>
                      <div className="tbl-img">
                        <img
                          src={`https://backend.nileshdawande.in/images/${nft?.ImageOne}`}
                          alt="img1"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="tbl-img">
                        <img
                          src={`https://backend.nileshdawande.in/images/${nft?.ImageTwo}`}
                          alt="img2"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="tbl-img">
                        <img
                          src={`https://backend.nileshdawande.in/images/${nft?.ImageThree}`}
                          alt="img3"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="tbl-img">
                        <img
                          src={`https://backend.nileshdawande.in/images/${nft?.ImageFour}`}
                          alt="img4"
                        />
                      </div>
                    </td>
                    <td>{nft.createdAt}</td>

                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#editcourse"
                        onClick={() => (
                          setNewsUpdateData({
                            NewsTitle: nft.NewsTitle,
                            NewsDesc: nft.NewsDesc,
                            ImageOne: nft.ImageOne,
                            ImageTwo: nft.ImageTwo,
                            ImageThree: nft.ImageThree,
                            ImageFour: nft.ImageFour,
                          }),
                          setUpdateId(nft._id)
                        )}
                      >
                        <i class="fa-solid fa-pen-to-square mx-2"></i>
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        onClick={() => handleDelete(nft._id)}
                      >
                        <i class="fa-solid fa-trash mx-2"></i>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/*Add modal form */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add News
              </h5>
              <button
                type="button"
                className="btn-close cus-btn-close "
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handlePostnewsData(e)}>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    News Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={newsData.NewsTitle}
                    onChange={(e) =>
                      handleOnChange("NewsTitle", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    News Description
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={newsData.NewsDesc}
                    onChange={(e) => handleOnChange("NewsDesc", e.target.value)}
                  />
                </div>
                <div className="mb-2">
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Image 1
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/jpg, image/jpeg, image/png"
                        onChange={(e) => handleImage1(e)}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-2">{$handleImage1}</div>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Image 2
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/jpg, image/jpeg, image/png"
                        onChange={(e) => handleImage2(e)}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-2">{$handleImage2}</div>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Image 3
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/jpg, image/jpeg, image/png"
                        onChange={(e) => handleImage3(e)}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-2">{$handleImage3}</div>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Image 4
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/jpg, image/jpeg, image/png"
                        onChange={(e) => handleImage4(e)}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-2">{$handleImage4}</div>
                    </div>
                  </div>
                </div>

                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-thumbs-up mx-2"></i>Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/*Edit  modal form */}
      <div
        className="modal fade"
        id="editcourse"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit News
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handleUpdatenewsData(e)}>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    News Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={newsUpdateData.NewsTitle}
                    onChange={(e) =>
                      handleUpdateOnChange("NewsTitle", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    News Description
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={newsUpdateData.NewsDesc}
                    onChange={(e) =>
                      handleUpdateOnChange("NewsDesc", e.target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Image 1
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/jpg, image/jpeg, image/png"
                        onChange={(e) => handleImage1(e)}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-2">{$handleImage1}</div>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Image 2
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/jpg, image/jpeg, image/png"
                        onChange={(e) => handleImage2(e)}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-2">{$handleImage2}</div>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Image 3
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/jpg, image/jpeg, image/png"
                        onChange={(e) => handleImage3(e)}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-2">{$handleImage3}</div>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Image 4
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/jpg, image/jpeg, image/png"
                        onChange={(e) => handleImage4(e)}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-2">{$handleImage4}</div>
                    </div>
                  </div>
                </div>

                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-thumbs-up mx-2"></i>Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer position="top-right" />
    </Layout>
  );
};
export default NewsPageSection;
