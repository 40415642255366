import { InfinitySpin } from "react-loader-spinner";
import { useState, useEffect } from "react";
import "./styles.css";
function Loader(props) {
  const [loadingVisiblity, setLoadingVisiblity] = useState("none");
  useEffect(() => {
    if (props.loading) {
      setLoadingVisiblity("flex");
    } else {
      setLoadingVisiblity("none");
    }
  }, [props.loading]);
  return (
    <>
      <div className="loading" style={{ display: loadingVisiblity }}>
        <div>
          <InfinitySpin width="200" height="100" color="#741b33" />
          <h5
            style={{
              color: "#741b33",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Loading...
          </h5>
        </div>
      </div>
    </>
  );
}
export default Loader;
